import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState, } from "react";
import FormatCrypto from "@hl/base-components/lib/FormatCrypto";
import { ArrowLeft, CheckCircle, CursorBox, } from "@hl/base-components/lib/assets/icons.generated";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { ActionIcon, Button, Card, Divider, Flex, Grid, Group, NumberInput, Space, Stack, Text, useMantineTheme, } from "@mantine/core";
import { useBids } from "@reservoir0x/reservoir-kit-ui";
import dayjs from "dayjs";
import { parseUnits } from "ethers/lib/utils";
import ReactTimeAgo from "react-time-ago";
import { useWalletClient } from "wagmi";
import { _CollectionType, EvmTxSaleKind, NftContractStandard, TransactionType, } from "../../../apollo/graphql.generated";
import { EXTERNAL_URL, getDetailPageUrl, getMintPageUrl, USER_ROUTES, } from "../../../config";
import { reactTimeAgoStyle } from "../../../utils/format";
import { User } from "../../auth/User";
import { useTransactionsDispatch } from "../../evm-tx/TransactionContext";
import { useModalStack } from "../../modal";
import MarketplaceConnectButton, { useMarketplaceAuth, } from "../components/MarketplaceConnectButton";
import MarketplacePopover from "../components/MarketplacePopover";
import MarketplaceSummaryCard from "../components/MarketplaceSummaryCard";
import RecentSales from "../components/RecentSales";
import RoyaltySettings from "../components/RoyaltySettings";
import SelectToken from "../components/SelectToken";
import { useCollectionMarketplaceDetailsQuery, useGetCollectionRoyaltyInfoQuery, useTokensNormalizedUserBalanceQuery, } from "../queries/marketplace.graphql.generated";
import { AcceptBidModalRenderer, AcceptBidStep, } from "./custom-accept-bid-modal/AcceptBidModalRenderer";
export const AcceptBidModalTitle = ({ address, isCollection, step = AcceptBidModalStep.Default, onBack, }) => step === AcceptBidModalStep.SelectToken ? (_jsxs(Flex, { gap: 8, align: "center", children: [_jsx(ActionIcon, { size: 24, onClick: () => onBack === null || onBack === void 0 ? void 0 : onBack(), children: _jsx(ArrowLeft, { width: 20, height: 20 }) }), _jsx(Text, { fw: WEIGHT_BOLD, children: "Choose token" })] })) : (_jsxs(Flex, { align: "center", children: [_jsx(User, { walletAddresses: [address], enableLink: false, textSize: "md", bold: true }), _jsxs(Text, { fw: WEIGHT_BOLD, sx: { whiteSpace: "pre" }, children: [" made ", isCollection ? "a collection offer" : "you an offer"] })] }));
const AcceptBidModalHighlight = (props) => {
    var _a, _b;
    const { bid, collectionData, tokenId: tId } = props;
    const token = (_a = bid.tokens) === null || _a === void 0 ? void 0 : _a[0];
    const { data: royaltyQuery } = useGetCollectionRoyaltyInfoQuery({
        variables: { collectionId: collectionData.id },
    });
    const royalty = royaltyQuery === null || royaltyQuery === void 0 ? void 0 : royaltyQuery.getPublicCollection.royalty;
    const royaltyAddress = royalty === null || royalty === void 0 ? void 0 : royalty.royaltyRecipient;
    const royaltyPercentage = royalty === null || royalty === void 0 ? void 0 : royalty.royaltyPercentageString;
    const royaltyBps = royaltyPercentage
        ? parseFloat(royaltyPercentage) * 100
        : 0;
    const [tokenId, setTokenId] = useState((_b = tId !== null && tId !== void 0 ? tId : token === null || token === void 0 ? void 0 : token.id) !== null && _b !== void 0 ? _b : "");
    const { data: walletClient } = useWalletClient();
    const [customRoyalty, setCustomRoyalty] = useState(royaltyBps || 0);
    const { data: collectionQuery } = useCollectionMarketplaceDetailsQuery({
        variables: { collectionId: collectionData.id },
    });
    const collection = collectionQuery === null || collectionQuery === void 0 ? void 0 : collectionQuery.getPublicCollectionDetails;
    useEffect(() => {
        if (royaltyBps != null) {
            setCustomRoyalty(royaltyBps);
        }
    }, [royaltyBps]);
    if (!bid.contractAddress) {
        return null;
    }
    // TODO: Make sure to only ever pass in token array of length ONE, because quantity is applied to all passed in tokens
    const tokens = [
        {
            collectionId: bid.contractAddress,
            tokenId,
            bidIds: [bid.reservoirId],
        },
    ];
    const collectionCombined = { ...collectionData, ...collection };
    return (_jsx(Stack, { pt: 16, spacing: 40, children: _jsx(AcceptBidModalRenderer, { chainId: props.bid.chainId, open: true, tokens: [...tokens], normalizeRoyalties: false, feesOnTopCustom: (order) => {
                const rawWeiPrice = (order === null || order === void 0 ? void 0 : order[0].totalRawPrice) || "0";
                const weiRoyalty = parseUnits(rawWeiPrice, 0)
                    .mul(customRoyalty)
                    .div(10000);
                if (!royaltyAddress || !weiRoyalty || weiRoyalty.isZero()) {
                    return [];
                }
                return [`${royaltyAddress}:${weiRoyalty.toString()}`];
            }, walletClient: walletClient, children: (acceptBidProps) => (_jsx(AcceptBidModalInner, { ...acceptBidProps, ...props, collectionData: collectionCombined, royaltyBps: customRoyalty, setRoyaltyBps: setCustomRoyalty, defaultBps: royaltyBps, tokenId: tokenId, setTokenId: setTokenId })) }) }));
};
export var AcceptBidModalStep;
(function (AcceptBidModalStep) {
    AcceptBidModalStep[AcceptBidModalStep["SelectToken"] = 0] = "SelectToken";
    AcceptBidModalStep[AcceptBidModalStep["Default"] = 1] = "Default";
})(AcceptBidModalStep || (AcceptBidModalStep = {}));
const AcceptBidModalInner = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const { acceptBidStep, stepData, transactionError, acceptBid, bid, onCompleted, tokenId, setTokenId, prices, defaultBps, setRoyaltyBps, royaltyBps, step = AcceptBidModalStep.Default, collectionData, quantity, setQuantity, } = props;
    const { data: bids } = useBids({ ids: [bid.reservoirId] }, undefined, !!(bid === null || bid === void 0 ? void 0 : bid.reservoirId), (_a = collectionData.chainId) !== null && _a !== void 0 ? _a : undefined);
    const updatedBid = bids === null || bids === void 0 ? void 0 : bids[0];
    const status = (updatedBid === null || updatedBid === void 0 ? void 0 : updatedBid.status) || bid.status;
    const feeBps = (_b = updatedBid.feeBps) !== null && _b !== void 0 ? _b : bid.feeBps;
    const isActive = status === "active";
    const errorText = isActive
        ? null
        : status === "fulfilled"
            ? "Sorry, offer accepted by someone else"
            : "Sorry, offer expired";
    const isCollectionBid = bid.criteriaKind !== "token";
    const quantityAvailable = bid.quantityRemaining;
    const is1155 = (collectionData === null || collectionData === void 0 ? void 0 : collectionData.standard) === NftContractStandard.ERC1155;
    const txHash = (_d = (_c = stepData === null || stepData === void 0 ? void 0 : stepData.currentStepItem) === null || _c === void 0 ? void 0 : _c.txHashes) === null || _d === void 0 ? void 0 : _d[0].txHash;
    const getNewTxnId = () => `txn-${Date.now()}`;
    const [txnId, setTxnId] = useState(getNewTxnId());
    const transactionDispatch = useTransactionsDispatch();
    const [error, setError] = useState();
    const [highlightModalStep, setHighlightModalStep] = useState(tokenId && isCollectionBid ? AcceptBidModalStep.SelectToken : step);
    const priceData = prices[0] || {};
    console.log("feedata", priceData, feeBps, updatedBid, bid);
    const { amount, marketplaceFee, netAmount, royalty, feesOnTop } = priceData;
    const finalMarketplaceFee = ((_e = bid.source) === null || _e === void 0 ? void 0 : _e.includes("highlight"))
        ? (amount * feeBps) / 10000
        : marketplaceFee;
    const netAmountQuantityAdjusted = netAmount * quantity;
    const amountQuantityAdjusted = amount * quantity;
    const marketplaceFeeQuantityAdjusted = finalMarketplaceFee * quantity;
    const royaltyQuantityAdjusted = royalty * quantity;
    const feesOnTopQuantityAdjusted = feesOnTop * quantity;
    // const percentage =
    //   marketplaceFee && amount ? (marketplaceFee / amount) * 100 : null;
    const symbol = (_g = (_f = bid === null || bid === void 0 ? void 0 : bid.price) === null || _f === void 0 ? void 0 : _f.currency) === null || _g === void 0 ? void 0 : _g.symbol;
    const theme = useMantineTheme();
    const { isFullyAuthenticated, walletAddress } = useMarketplaceAuth();
    const { clearModals, setModalProps } = useModalStack();
    const token = (_h = bid === null || bid === void 0 ? void 0 : bid.tokens) === null || _h === void 0 ? void 0 : _h[0];
    const [tokenImageUrl, setTokenImageUrl] = useState((_j = token === null || token === void 0 ? void 0 : token.imageUrl) !== null && _j !== void 0 ? _j : "");
    const collection = bid.collection
        ? bid.collection
        : {
            ...collectionData,
            onchainId: (_k = collectionData.onchainId) !== null && _k !== void 0 ? _k : "",
            id: (_l = collectionData.id) !== null && _l !== void 0 ? _l : "",
        };
    const linkToResource = collection
        ? isCollectionBid
            ? getMintPageUrl(collection)
            : getDetailPageUrl(collection, (_m = token === null || token === void 0 ? void 0 : token.id) !== null && _m !== void 0 ? _m : "")
        : "";
    const { data, loading } = useTokensNormalizedUserBalanceQuery({
        variables: {
            tokenIds: [tokenId],
            nonFilteredUserAddress: walletAddress !== null && walletAddress !== void 0 ? walletAddress : "",
            fromS3: true,
            isImportedCollection: collectionData.collectionType === _CollectionType.Imported,
            collectionId: (_o = collectionData.id) !== null && _o !== void 0 ? _o : "",
        },
        skip: !tokenId || !collectionData.id || !is1155,
    });
    const userBalance = (_q = (_p = data === null || data === void 0 ? void 0 : data.getPublicCollection.tokensNormalized) === null || _p === void 0 ? void 0 : _p[0].userBalance) !== null && _q !== void 0 ? _q : 0;
    const max = parseInt(String(quantityAvailable > userBalance ? userBalance : quantityAvailable));
    const acceptBidAndCreateTx = useCallback(() => {
        var _a;
        setError("");
        if (!txnId) {
            return;
        }
        transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
            type: "START_TRANSACTION",
            payload: {
                id: txnId,
                entityId: (_a = collectionData === null || collectionData === void 0 ? void 0 : collectionData.id) !== null && _a !== void 0 ? _a : "",
                transactionType: TransactionType.EVM_TOKEN_SALE,
                collectionType: _CollectionType.GenerativeSeries,
            },
        });
        acceptBid();
    }, [acceptBid, txnId]);
    useEffect(() => {
        var _a, _b;
        if (txHash && txnId) {
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_ARGS",
                payload: {
                    id: txnId,
                    args: (_a = stepData === null || stepData === void 0 ? void 0 : stepData.currentStepItem) === null || _a === void 0 ? void 0 : _a.data,
                    metadata: {
                        collectionId: collectionData.id,
                        nftContractAddress: (_b = collectionData.address) !== null && _b !== void 0 ? _b : "",
                        reservoirOrderId: bid.reservoirId,
                        saleKind: EvmTxSaleKind.OFFER,
                        tokenId: tokenId !== null && tokenId !== void 0 ? tokenId : "",
                    },
                },
            });
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id: txnId,
                    hash: txHash,
                },
            });
            setTxnId(null);
        }
    }, [stepData]);
    const actionButton = useMemo(() => {
        if (!isFullyAuthenticated)
            return _jsx(MarketplaceConnectButton, {});
        if (acceptBidStep === AcceptBidStep.Complete) {
            return (_jsxs(Grid, { children: [_jsx(Grid.Col, { span: 6, children: _jsxs(Button, { component: "a", href: linkToResource, onClick: () => clearModals(), w: "100%", px: "md", color: SECONDARY_COLOR, size: "xl", children: ["View ", isCollectionBid ? "collection" : "token"] }) }), _jsx(Grid.Col, { span: 6, children: _jsx(Button, { component: "a", size: "xl", w: "100%", px: "md", href: USER_ROUTES.user.marketplace.receivedOffers.replace(":slug", walletAddress !== null && walletAddress !== void 0 ? walletAddress : ""), target: "_blank", children: "Manage offers" }) })] }));
        }
        const isExecuting = acceptBidStep === AcceptBidStep.Finalizing ||
            acceptBidStep === AcceptBidStep.ApproveMarketplace;
        return highlightModalStep === AcceptBidModalStep.Default &&
            isCollectionBid ? (_jsx(Button, { size: "xl", onClick: () => setHighlightModalStep(AcceptBidModalStep.SelectToken), w: "100%", children: _jsxs(Group, { spacing: "xs", children: [_jsx(CursorBox, {}), " Choose token"] }) })) : (_jsx(Button, { size: "xl", onClick: () => {
                acceptBidAndCreateTx();
                setError("");
            }, loading: isExecuting, disabled: isExecuting || !tokenId || !netAmountQuantityAdjusted || !isActive, w: "100%", children: _jsxs(Group, { spacing: "xs", children: [!isExecuting && _jsx(CheckCircle, {}), " ", errorText
                        ? errorText
                        : tokenId
                            ? "Accept offer"
                            : "Please select a token"] }) }));
    }, [
        isFullyAuthenticated,
        transactionError,
        acceptBidStep,
        isCollectionBid,
        highlightModalStep,
        acceptBidAndCreateTx,
    ]);
    useEffect(() => {
        setModalProps({
            title: (_jsx(AcceptBidModalTitle, { address: bid.makerAddress, isCollection: bid.criteriaKind !== "token", step: highlightModalStep, onBack: () => setHighlightModalStep(AcceptBidModalStep.Default) })),
        });
    }, [highlightModalStep]);
    useEffect(() => {
        if (acceptBidStep === AcceptBidStep.Complete) {
            setModalProps({ title: _jsx(Text, { fw: WEIGHT_BOLD, children: "Offer accepted" }) });
            onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted();
        }
    }, [acceptBidStep]);
    useEffect(() => {
        var _a;
        if (!transactionError) {
            setError("");
        }
        else {
            if ((_a = transactionError === null || transactionError === void 0 ? void 0 : transactionError.message) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes("balance too low")) {
                setError("Insufficient balance, add funds then try again");
            }
            else if (transactionError === null || transactionError === void 0 ? void 0 : transactionError.message.toLowerCase().includes("user rejected the request")) {
                setError("Request cancelled");
            }
            else if (transactionError === null || transactionError === void 0 ? void 0 : transactionError.message.includes("No fillable orders")) {
                setError("Order is not valid");
            }
            else {
                setError(transactionError
                    .shortMessage || "Ooops, something went wrong");
            }
        }
    }, [transactionError]);
    const imageUrl = isCollectionBid
        ? highlightModalStep === AcceptBidModalStep.Default
            ? collection === null || collection === void 0 ? void 0 : collection.imageUrl
            : tokenImageUrl
        : tokenImageUrl;
    const isShowingRoyalty = !isCollectionBid || highlightModalStep === AcceptBidModalStep.SelectToken;
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { children: [_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "Offer" }), _jsx(MarketplaceSummaryCard, { imageSize: 80, symbol: symbol, price: amount, quantity: quantity, imageUrl: imageUrl, tokenId: token === null || token === void 0 ? void 0 : token.id, collectionName: (_r = bid === null || bid === void 0 ? void 0 : bid.collection) === null || _r === void 0 ? void 0 : _r.name, floorPrice: bid.floorPrice, validUntil: (_s = bid === null || bid === void 0 ? void 0 : bid.expiredAt) !== null && _s !== void 0 ? _s : undefined })] }), acceptBidStep !== AcceptBidStep.Complete && (_jsxs(_Fragment, { children: [_jsxs(Stack, { children: [_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "Expires" }), _jsx(Card, { px: 16, py: 12, children: _jsxs(Flex, { justify: "space-between", children: [_jsx(Text, { size: "sm", children: bid.expiredAt
                                                ? `${dayjs(bid.expiredAt).format("MMM. DD @ hh:mma")} ${Intl.DateTimeFormat().resolvedOptions().timeZone}`
                                                : "N/A" }), _jsxs(Text, { color: theme.colors.secondaryText[0], size: "sm", children: ["in", " ", _jsx(ReactTimeAgo, { date: dayjs(bid.expiredAt).toDate(), timeStyle: reactTimeAgoStyle(), future: true, locale: "en-US" })] })] }) })] }), _jsx(_Fragment, { children: is1155 && (_jsxs(Stack, { spacing: 4, children: [_jsx(NumberInput, { label: "Number of tokens to sell", placeholder: "1", onChange: (value) => setQuantity(parseInt(String(value))), defaultValue: 1, value: quantity, min: 1, max: max, styles: {
                                        input: {
                                            paddingRight: 24,
                                        },
                                        rightSection: {
                                            paddingRight: 0,
                                        },
                                    } }), _jsxs(Text, { size: "xs", color: theme.colors.secondaryText[0], children: ["Bid is for ", quantityAvailable, " tokens.", " ", !loading && userBalance < quantityAvailable
                                            ? `You own ${userBalance}.`
                                            : ""] })] })) }), highlightModalStep === AcceptBidModalStep.SelectToken &&
                        collectionData && (_jsx(SelectToken, { collectionData: collectionData, tokenId: tokenId, selectToken: (token) => {
                            var _a;
                            setTokenId(token.tokenId);
                            setTokenImageUrl((_a = token.imageSrc) !== null && _a !== void 0 ? _a : "");
                        }, attributeKey: (_t = bid.attributeKey) !== null && _t !== void 0 ? _t : undefined, attributeValue: (_u = bid.attributeValue) !== null && _u !== void 0 ? _u : undefined })), isShowingRoyalty && (_jsx(RoyaltySettings, { royaltyBps: royaltyBps, setRoyaltyBps: setRoyaltyBps, defaultBps: defaultBps })), highlightModalStep === AcceptBidModalStep.Default && collection && (_jsx(RecentSales, { name: collection.name || "", isCollectionBid: isCollectionBid, address: collection.address })), _jsxs(Stack, { spacing: 0, children: [_jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Offer price" }), _jsx(FormatCrypto, { size: "xs", color: TEXT_COLOR.SECONDARY, amount: amountQuantityAdjusted, symbol: symbol })] }), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(MarketplacePopover, { text: `Highlight will charge ${feeBps ? `a ${(feeBps / 100).toFixed(1)}%` : "no"} fee for this marketplace listings.`, url: EXTERNAL_URL.MARKETPLACE_FEE_EXPLANATION, children: _jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Marketplace fee" }) }), _jsx(FormatCrypto, { size: "xs", color: TEXT_COLOR.SECONDARY, amount: marketplaceFeeQuantityAdjusted, symbol: symbol })] }), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(MarketplacePopover, { text: feesOnTopQuantityAdjusted
                                            ? "100% of creator royalties are paid to the creator of this collection."
                                            : "This creator hasn't enabled creator royalties.", url: royaltyQuantityAdjusted
                                            ? EXTERNAL_URL.ROYALTY_FEE_EXPLANATION
                                            : undefined, children: _jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Creator royalties" }) }), defaultBps ? (_jsx(FormatCrypto, { size: "xs", color: TEXT_COLOR.SECONDARY, amount: feesOnTopQuantityAdjusted, symbol: symbol })) : (_jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "0%" }))] }), _jsx(Divider, { my: "sm", size: 0.5 }), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(Text, { size: "lg", fw: WEIGHT_BOLD, children: "Total earnings" }), _jsx(FormatCrypto, { size: "lg", fw: WEIGHT_BOLD, amount: netAmountQuantityAdjusted, symbol: symbol })] })] })] })), _jsxs(Stack, { spacing: 8, sx: { overflow: "hidden" }, children: [_jsx(Space, { h: 8, mx: -16, sx: {
                            borderTop: `1px solid ${theme.colors.divider[0]}`,
                            width: "auto !important",
                        } }), actionButton, error && (_jsx(Text, { size: "xs", align: "center", color: theme.colors.errorStatus[0], children: error }))] })] }));
};
export default AcceptBidModalHighlight;
