import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { Refresh } from "@hl/base-components/lib/assets/icons.generated";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { ActionIcon, createStyles, NumberInput, Stack, Switch, Text, } from "@mantine/core";
import MarketplacePopover from "./MarketplacePopover";
const useStyles = createStyles({
    fullWidth: {
        width: "100%",
    },
});
const RoyaltySettings = ({ royaltyBps, setRoyaltyBps, defaultBps = 0, }) => {
    const [honorRoyalty, setHonorRoyalty] = useState(true);
    const { classes } = useStyles();
    useEffect(() => {
        if (royaltyBps > (defaultBps || 0)) {
            setRoyaltyBps(defaultBps || 0);
        }
    }, [royaltyBps]);
    const showRoyalties = defaultBps != undefined && defaultBps > 0;
    return showRoyalties ? (_jsxs(Stack, { spacing: 0, children: [_jsx(MarketplacePopover, { text: "100% of creator royalties are paid to the creator of this collection.", children: _jsx(Text, { fw: WEIGHT_BOLD, size: "sm", children: "Creator royalties" }) }), _jsx(Switch, { label: _jsxs(Text, { size: "sm", children: ["Honor creator royalties of ", defaultBps / 100, "%"] }), labelPosition: "left", w: "100%", checked: honorRoyalty, classNames: {
                    labelWrapper: classes.fullWidth,
                    body: classes.fullWidth,
                }, onClick: (event) => {
                    setHonorRoyalty(event.currentTarget.checked);
                    setRoyaltyBps(defaultBps);
                } }), !honorRoyalty && (_jsx(NumberInput, { max: defaultBps / 100, value: royaltyBps / 100, precision: 2, placeholder: defaultBps / 100 + "%", removeTrailingZeros: true, onBlur: (event) => {
                    setRoyaltyBps(Math.floor(parseFloat(event.target.value || "0") * 100) || 0);
                }, rightSection: _jsx(ActionIcon, { right: 8, onClick: () => setRoyaltyBps(defaultBps), children: _jsx(Refresh, {}) }), onChange: (value) => setRoyaltyBps((value || 0) * 100 || 0), inputWrapperOrder: ["label", "input", "description", "error"] }))] })) : null;
};
export default RoyaltySettings;
