import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createConfig, WagmiProvider } from "@privy-io/wagmi";
import { arbitrum, base, mainnet, optimism, polygon, zora, sepolia, mantle, zkSync, } from "@wagmi/chains";
import { http } from "viem";
import { baseSepolia, shape, forma, scroll, cyber } from "viem/chains";
import { envConfig } from "../../../../config";
import { zero, apechain } from "./customChains";
// Base array of chains
const chainsList = [
    mainnet,
    polygon,
    optimism,
    arbitrum,
    zora,
    base,
    sepolia,
    baseSepolia,
    forma,
    mantle,
    scroll,
    zkSync,
    shape,
    cyber,
    zero,
    apechain,
];
// Readonly version for wagmi
const wagmiChains = chainsList;
// Mutable version for Privy
export const supportedChains = [...chainsList];
const apiKeys = envConfig.wagmiProviders;
const wagmiConfig = createConfig({
    chains: wagmiChains,
    transports: {
        [mainnet.id]: http(`https://eth-mainnet.g.alchemy.com/v2/${apiKeys.alchemy_api_key}`),
        [polygon.id]: http(),
        [optimism.id]: http(`https://opt-mainnet.g.alchemy.com/v2/${apiKeys.alchemy_optimism_api_key}`),
        // infura as a backup only
        [arbitrum.id]: http(`https://arbitrum-mainnet.infura.io/v3/${apiKeys.infura_api_key}`),
        [zora.id]: http(),
        [base.id]: http(apiKeys.base_rpc_url),
        [sepolia.id]: http(`https://eth-sepolia.g.alchemy.com/v2/${apiKeys.alchemy_api_key}`),
        [baseSepolia.id]: http(`https://base-sepolia.g.alchemy.com/v2/${apiKeys.alchemy_api_key}`),
        [forma.id]: http(),
        [mantle.id]: http(),
        [scroll.id]: http(),
        [zkSync.id]: http(),
        [shape.id]: http(),
        [cyber.id]: http(),
        [zero.id]: http(zero.rpcUrls.default.http[0]),
        [apechain.id]: http(apechain.rpcUrls.default.http[0]),
    },
});
export const CustomPrivyWagmiConnector = ({ children, }) => {
    return _jsx(WagmiProvider, { config: wagmiConfig, children: children });
};
