export const STORAGE_KEYS = Object.freeze({
    auth: "highlight.auth",
    analyticsDevice: "highlight.analytics.device",
    quickStartPreference: "highlight.quickstart.preference",
    highlightTipsActivity: "highlight.tips.activity",
    clientVersionLastFetch: "highlight.client.version",
    newUserOffers: "highlight.user.receivedOffers.",
    emailUpdates: "highlight.user.emailUpdates.",
});
export const JWT_EXPIRATION_DAYS = 10 * 365 * 10; // 10 years (no expiration)
export const IN_DEVELOPMENT = process.env.REACT_APP_ENV !== "production";
export var ApolloClientErrors;
(function (ApolloClientErrors) {
    ApolloClientErrors["CLIENT_VERSION_DONT_MATCH"] = "ClientVersionDontMatch";
})(ApolloClientErrors || (ApolloClientErrors = {}));
const getRequiredEnvVar = (name, fallback) => {
    const value = getOptionalEnvVar(name, fallback);
    if (!value) {
        console.error(`env var ${name} is not set`);
    }
    return value;
};
const getOptionalEnvVar = (name, fallback) => {
    var _a;
    return (_a = process.env[name]) !== null && _a !== void 0 ? _a : fallback;
};
export const envConfig = {
    graphql: {
        httpUrl: process.env.NEXT_PUBLIC_WEB_GRAPHQL_URL ||
            getRequiredEnvVar("REACT_APP_WEB_GRAPHQL_URL"),
    },
    websocket: {
        url: process.env.NEXT_PUBLIC_WEBSOCKET_URL ||
            getRequiredEnvVar("REACT_APP_WEBSOCKET_URL"),
        mqtt: process.env.NEXT_PUBLIC_WEBSOCKET_MQTT_URL ||
            getOptionalEnvVar("REACT_APP_WEBSOCKET_MQTT_URL"),
    },
    wagmiProviders: {
        alchemy_api_key: process.env.NEXT_PUBLIC_ALCHEMY_API_KEY ||
            getRequiredEnvVar("REACT_APP_ALCHEMY_API_KEY"),
        infura_api_key: process.env.NEXT_PUBLIC_INFURA_API_KEY ||
            getRequiredEnvVar("REACT_APP_INFURA_API_KEY"),
        alchemy_optimism_api_key: process.env.NEXT_PUBLIC_ALCHEMY_OPTIMISM_API_KEY ||
            getRequiredEnvVar("REACT_APP_ALCHEMY_OPTIMISM_API_KEY"),
        base_rpc_url: process.env.NEXT_PUBLIC_BASE_RPC_URL ||
            process.env.REACT_APP_BASE_RPC_URL,
        base_goerli_rpc_url: process.env.NEXT_PUBLIC_BASE_GOERLI_RPC_URL ||
            process.env.REACT_APP_BASE_GOERLI_RPC_URL,
    },
    authProviders: {
        privy: {
            appId: process.env.NEXT_PUBLIC_PRIVY_APP_ID ||
                getRequiredEnvVar("REACT_APP_PRIVY_APP_ID"),
        },
    },
    walletConnectors: {
        wallet_connect_project_id: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID ||
            getRequiredEnvVar("REACT_APP_WALLET_CONNECT_PROJECT_ID"),
    },
    user: {
        baseUrl: process.env.NEXT_PUBLIC_USER_BASE_URL ||
            getRequiredEnvVar("REACT_APP_USER_BASE_URL"),
    },
    home: {
        baseUrl: process.env.NEXT_PUBLIC_HOME_BASE_URL ||
            getRequiredEnvVar("REACT_APP_HOME_BASE_URL"),
    },
    tools: {
        baseUrl: process.env.NEXT_PUBLIC_TOOLS_BASE_URL ||
            getRequiredEnvVar("REACT_APP_TOOLS_BASE_URL"),
    },
    mint: {
        baseUrl: process.env.NEXT_PUBLIC_MINT_BASE_URL ||
            getRequiredEnvVar("REACT_APP_MINT_BASE_URL"),
    },
    marketplaceProxyApiUrl: process.env.NEXT_PUBLIC_MARKETPLACE_PROXY_API_URL ||
        getOptionalEnvVar("REACT_APP_MARKETPLACE_PROXY_API_URL"),
    marketplaceSource: process.env.NEXT_PUBLIC_MARKETPLACE_SOURCE ||
        getOptionalEnvVar("REACT_APP_MARKETPLACE_SOURCE"),
    marketplaceFeeRecipient: process.env.NEXT_PUBLIC_MARKETPLACE_FEE_RECIPIENT ||
        getOptionalEnvVar("REACT_APP_MARKETPLACE_FEE_RECIPIENT"),
    marketplaceFeeBps: process.env.NEXT_PUBLIC_MARKETPLACE_FEE_BPS ||
        getOptionalEnvVar("REACT_APP_MARKETPLACE_FEE_BPS"),
    marketplaceFeeOnTopDollar: process.env.NEXT_PUBLIC_MARKETPLACE_FEE_ON_TOP_DOLLAR ||
        getOptionalEnvVar("REACT_APP_MARKETPLACE_FEE_ON_TOP_DOLLAR"),
    isDevelopment: process.env.REACT_APP_ENV !== "production",
    analytics: {
        amplitudeKey: process.env.REACT_APP_AMPLITUDE_KEY ||
            process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
    },
    sanity: {
        projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ||
            getOptionalEnvVar("REACT_APP_SANITY_PROJECT_ID") ||
            "h9hgfp8m",
        dataset: process.env.NEXT_PUBLIC_SANITY_DATASET ||
            getOptionalEnvVar("REACT_APP_SANITY_DATASET") ||
            "production",
    },
};
export const LOGGER_API = process.env.REACT_APP_LOGGER_API || null;
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || null;
export const ALCHEMY_API_KEY_ENS = process.env.REACT_APP_ALCHEMY_API_KEY_ENS || null;
/* SEO Configs */
export const HIGHLIGHT_PAGE_TITLE_SIGNATURE = " | Highlight";
export const EXTERNAL_URL = Object.freeze({
    ARBIGOERLISCAN: "https://goerli.arbiscan.io/",
    ARBISCAN: "https://arbiscan.io/",
    BASEGOERLISCAN: "https://goerli.basescan.org/",
    BASESEPOLIASCAN: "https://sepolia.basescan.org/",
    BLOG: "https://highlight.mirror.xyz/",
    ETHERSCAN: "https://etherscan.io",
    GOERLISCAN: "https://goerli.etherscan.io/",
    SEPOLIASCAN: "https://sepolia.etherscan.io/",
    KNOWLEDGE_BASE: "https://support.highlight.xyz/knowledge-base/",
    LINKEDIN: "https://www.linkedin.com/company/highlight-xyz/about/",
    MUMBAISCAN: "https://mumbai.polygonscan.com/",
    OPENSEA: "https://opensea.io/assets",
    OPENSEA_BASE: "https://opensea.io",
    OPENSEA_TESTNET: "https://testnets.opensea.io/assets",
    OPTIMISMSCAN: "https://optimistic.etherscan.io/",
    OPTIMISTICGOERLISCAN: "https://goerli-optimism.etherscan.io/",
    POLYGONSCAN: "https://polygonscan.com",
    SUPPORT: "https://support.highlight.xyz",
    COMMUNITY: "https://community.highlight.xyz",
    HL_FARCASTER: "https://warpcast.com/highlight",
    HL_EMAIL: "gm@highlight.xyz",
    HL_MARKETING: "https://highlight.xyz",
    HL_TWITTER: "https://twitter.com/Highlight_xyz",
    HL_DISCORD: "https://discord.gg/Rb6P9BxctR",
    HL_GITHUB: "https://github.com/highlightxyz/generative-art",
    YOUTUBE: "https://www.youtube.com/@highlightxyz",
    MINT_FEE_HELP_ARTICLE: "https://support.highlight.xyz/knowledge-base/highlight-toolkit/other-faqs/highlight-mint-fees",
    AUCTIONS: "https://support.highlight.xyz/knowledge-base/for-creators/selling-your-nfts/available-sale-methods#auctions-on-highlight",
    FAUCET_HELP_ARTICLE: "https://support.highlight.xyz/knowledge-base/for-creators/learn-the-creation-flows/the-importance-of-testing-your-collections#getting-testnet-eth",
    // TODO: ADD LINKS BELOW,
    MARKETPLACE_FEE_EXPLANATION: "",
    ROYALTY_FEE_EXPLANATION: "",
    IPFS_GATEWAY: "https://ipfs.io/ipfs",
    ARWEAVE_GATEWAY: "https://arweave.net",
    DFS_FALLBACK_IMAGE_URL: "https://highlight-creator-assets-prod.s3.amazonaws.com/main/image/404.png",
    PROTOCOL_DOCS: "https://support.highlight.xyz/knowledge-base/highlight-protocol/introduction",
    SWAP_CURRENCIES_DOC: "https://support.highlight.xyz/knowledge-base/for-collectors/highlight-marketplace/exchange-currency-across-chains",
    DEFAULT_AVATAR_BASE_URL: "https://highlight-creator-assets.highlight.xyz/main/avatar",
});
export const DOMAINS = Object.freeze({
    GATE: { name: "gate", devPort: "3001" },
    MINT: { name: "mint", devPort: "3003" },
    TOOLS: { name: "tools", devPort: "3002" },
});
export const getToolsUrl = () => {
    const { protocol, host } = window.location;
    const hostParts = host.split(".");
    return host.includes("localhost")
        ? host.replace(new RegExp("[0-9]+"), DOMAINS.TOOLS.devPort)
        : `${protocol}//${DOMAINS.TOOLS.name}.${hostParts[1]}.${hostParts[2]}`;
};
export const TOOLS_ROUTES = {
    account: {
        settings: {
            email: "/account/settings/email",
        },
    },
    collections: {
        manage: `${envConfig.tools.baseUrl}/collections/:collectionId/manage`,
    },
};
export const USER_ROUTES = {
    user: {
        profile: `${envConfig.user.baseUrl}/@:slug`,
        marketplace: {
            default: `${envConfig.user.baseUrl}/@:slug`,
            collected: `${envConfig.user.baseUrl}/@:slug/collected`,
            listings: `${envConfig.user.baseUrl}/@:slug/listings`,
            sentOffers: `${envConfig.user.baseUrl}/@:slug/sent-offers`,
            receivedOffers: `${envConfig.user.baseUrl}/@:slug/received-offers`,
        },
    },
};
const ONCHAIN_URLS_ENABLED = (process.env.NEXT_PUBLIC_ONCHAIN_URLS_ENABLED ||
    getOptionalEnvVar("REACT_APP_ONCHAIN_URLS_ENABLED")) === "true";
export const getMintPageUrl = ({ id, onchainId }, options = {}) => {
    const baseUrl = envConfig.mint.baseUrl;
    const query = options.marketplace ? "?marketplace=true" : "";
    if (ONCHAIN_URLS_ENABLED && onchainId) {
        return `${baseUrl}/${onchainId}${query}`;
    }
    return `${baseUrl}/${id}${query}`;
};
export const getDetailPageUrl = (collection, tokenId) => `${getMintPageUrl(collection)}/t/${tokenId}`;
