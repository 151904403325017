import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { useAppColorScheme } from "@hl/base-components/lib/theme/useColorScheme";
import { PrivyProvider, usePrivy, useWallets } from "@privy-io/react-auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { envConfig } from "../../../../config";
import { CustomPrivyWagmiConnector, supportedChains, } from "../../../eth/providers/privy/CustomPrivyWagmiConnector";
import { initiateUserSignIn, useAuthDispatch, userAuthFetched, userSignInFailure, userSignInSuccess, userSignOut, } from "../../AuthContext";
import { useCompleteSignInWithJwtMutation } from "../../queries/auth.graphql.generated";
import { identityVar } from "../../vars";
const queryClient = new QueryClient();
export const CustomPrivyProvider = ({ children }) => {
    const { colorScheme } = useAppColorScheme({});
    return (_jsx(PrivyProvider, { appId: envConfig.authProviders.privy.appId, config: {
            supportedChains,
            externalWallets: {
                coinbaseWallet: {
                    connectionOptions: "all",
                },
            },
            embeddedWallets: {
                createOnLogin: "users-without-wallets",
            },
            loginMethods: ["email", "wallet"],
            appearance: {
                walletList: [
                    "detected_wallets",
                    "metamask",
                    "coinbase_wallet",
                    "zerion",
                    "rabby_wallet",
                    "wallet_connect",
                ],
                theme: colorScheme,
                accentColor: "#000",
                logo: colorScheme === "dark"
                    ? "https://tools.highlight.xyz/images/highlight-brand-light.png"
                    : "https://tools.highlight.xyz/images/highlight-brand-dark.png",
            },
        }, children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(CustomPrivyWagmiConnector, { children: _jsx(PrivyAuthBootstrap, { children: children }) }) }) }));
};
export const PrivyAuthBootstrap = ({ children }) => {
    var _a;
    const dispatch = useAuthDispatch();
    const { ready, user, logout, getAccessToken } = usePrivy();
    const identity = useReactiveVar(identityVar);
    const [completeSignIn] = useCompleteSignInWithJwtMutation();
    // technically it only registers Privy user in our backend
    // and possibly needed only for new signups
    const jwtLogin = useCallback(async () => {
        const accessToken = await getAccessToken();
        if (accessToken) {
            initiateUserSignIn(dispatch);
            const completeResult = await completeSignIn({
                variables: { jwt: accessToken },
            });
            if (!completeResult.data || completeResult.errors) {
                userSignInFailure(dispatch);
            }
            else {
                const data = completeResult.data.completeSignInWithJWT;
                userSignInSuccess(data.account.evmAddresses[0].address, data.account.id, data.account.email, dispatch);
                return true;
            }
        }
    }, [dispatch]);
    const privyAddress = (_a = user === null || user === void 0 ? void 0 : user.wallet) === null || _a === void 0 ? void 0 : _a.address;
    const fullLogout = useCallback(async () => {
        await logout();
        userSignOut(dispatch);
    }, [dispatch]);
    // there are 3 states that must be in sync: Privy, identity and wagmi
    useEffect(() => {
        var _a, _b;
        if (!ready || !identity)
            return;
        const { account } = identity;
        if (account && privyAddress) {
            const accountAddress = account.evmAddresses[0];
            if (accountAddress !== privyAddress) {
                console.log("privy wallet must match identity wallet, logout", {
                    accountAddress,
                    privyAddress,
                });
                fullLogout();
            }
            else if (accountAddress) {
                userSignInSuccess(accountAddress, account.id, (_b = (_a = user.email) === null || _a === void 0 ? void 0 : _a.address) !== null && _b !== void 0 ? _b : "", dispatch);
            }
        }
        else if (privyAddress && !identity.account) {
            // lost jwt token
            jwtLogin();
        }
        else if (!privyAddress && identity.account) {
            console.log("no privy wallet, reset identity");
            fullLogout();
        }
        else {
            userAuthFetched(dispatch);
        }
    }, [ready, privyAddress, identity, dispatch, fullLogout]);
    const { wallets, ready: wagmiReady } = useWallets();
    useEffect(() => {
        if (!ready || !wagmiReady)
            return;
        if (privyAddress && !wallets.some((w) => w.address === privyAddress)) {
            console.log("no matching wagmi wallet connected, logout", {
                wallets,
                privyAddress,
            });
            fullLogout();
        }
    }, [ready, privyAddress, wallets, fullLogout]);
    return _jsx(_Fragment, { children: children });
};
