import "./init";
import "@hl/shared-features/lib/features/logging/openTelemetry";
import { StrictMode } from "react";

import { ApolloProvider } from "@apollo/client";
import WebsocketMqttConnection from "@hl/shared-features/lib/features/websocket/WebsocketMqttConnection";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import { App } from "./app";
import { MINT_BASE_URL } from "./config";
import { apolloClient } from "./services/apolloClient";
import { AppThemeProvider } from "./styles/theme";

TimeAgo.addDefaultLocale(en);

const container = document.getElementById("root");
const root = createRoot(container!);

const basename = new URL(MINT_BASE_URL).pathname;

root.render(
  <StrictMode>
    <WebsocketMqttConnection>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter basename={basename}>
          <HelmetProvider>
            <AppThemeProvider>
              <App />
            </AppThemeProvider>
          </HelmetProvider>
        </BrowserRouter>
      </ApolloProvider>
    </WebsocketMqttConnection>
  </StrictMode>
);
