// Add Backend Notification Types here.
export var HLServiceEventType;
(function (HLServiceEventType) {
    HLServiceEventType["CollectionDeployReady"] = "CollectionDeployReady";
    HLServiceEventType["CollectionDeployFailed"] = "CollectionDeployFailed";
    HLServiceEventType["HighlightLiveFeed"] = "highlight-live-feed";
})(HLServiceEventType || (HLServiceEventType = {}));
// All Frontend specific notification types go here
// mostly used to group BE notifications by type for easier FE consumption
export var AppEventType;
(function (AppEventType) {
    AppEventType["CreateCollectionUpdate"] = "CreateCollectionUpdate";
})(AppEventType || (AppEventType = {}));
// Map Backend Notification to Frontend Notification when necessary
// If the notification type is not present in this mapping, the original
// notification type will be used
export const WebsocketEventMapping = {
    [HLServiceEventType.CollectionDeployReady]: AppEventType.CreateCollectionUpdate,
    [HLServiceEventType.CollectionDeployFailed]: AppEventType.CreateCollectionUpdate,
};
