/**
 * This function is used to mask the wallet address.
 */
export const maskAddress = (string, beginningLength = 10, endingLength = 5) => {
    if (!string || beginningLength + endingLength >= (string === null || string === void 0 ? void 0 : string.length)) {
        return string;
    }
    return (string.substring(0, beginningLength) +
        "..." +
        string.substring(string.length - endingLength));
};
