import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useContext, useEffect, useState, } from "react";
import mqtt from "mqtt";
import { envConfig } from "../../config";
import Emitter from "./EventEmitter";
import { WebsocketEventMapping, } from "./events";
export const MqttContext = createContext(null);
const WebsocketMqttConnection = ({ children }) => {
    const [mqttState, setMqttState] = useState({
        client: null,
        status: "Connecting",
        error: null,
    });
    const client = mqttState.client;
    useEffect(() => {
        const client = mqtt.connect(envConfig.websocket.mqtt ||
            "wss://a3h8w8sogtyzye-ats.iot.us-east-1.amazonaws.com/mqtt?x-amz-customauthorizer-name=test_auth");
        setMqttState({ client, status: "Connecting", error: null });
        return () => {
            var _a;
            (_a = mqttState.client) === null || _a === void 0 ? void 0 : _a.end();
            setMqttState({ client: null, status: "Connecting", error: null });
        };
    }, []);
    useEffect(() => {
        if (client) {
            client.on("error", (error) => {
                console.error("Connection error: ", error);
                setMqttState({ client, status: "Errored", error });
                client.end();
            });
            client.on("reconnect", () => {
                setMqttState({ client, status: "Reconnecting", error: null });
            });
            client.on("message", (topic, message) => {
                var _a;
                try {
                    const _serviceType = topic;
                    const type = (_a = WebsocketEventMapping[_serviceType]) !== null && _a !== void 0 ? _a : _serviceType;
                    const notification = {
                        _serviceType,
                        type,
                        payload: message.toString(),
                    };
                    Emitter.emit(type, notification);
                }
                catch (error) {
                    console.error("Error parsing Mqtt message", error);
                }
            });
        }
    }, [client]);
    return (_jsx(MqttContext.Provider, { value: mqttState, children: children }));
};
export default WebsocketMqttConnection;
export const useMqtt = () => {
    const mqttState = useContext(MqttContext);
    if (mqttState == null) {
        throw new Error("MqttContext not found");
    }
    return mqttState;
};
