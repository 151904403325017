import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import FormatCrypto from "@hl/base-components/lib/FormatCrypto";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Button, Divider, Flex, Grid, NumberInput, Space, Stack, Text, useMantineTheme, } from "@mantine/core";
import { ListStep, ListModal, useReservoirClient, } from "@reservoir0x/reservoir-kit-ui";
import { useAccount, useConfig } from "wagmi";
import { switchChain } from "wagmi/actions";
import { _CollectionType, NftContractStandard, TransactionType, } from "../../../apollo/graphql.generated";
import { EXTERNAL_URL, getDetailPageUrl, USER_ROUTES, } from "../../../config";
import EmailUpdates from "../../email-updates/EmailUpdates";
import { useTransactionsDispatch } from "../../evm-tx/TransactionContext";
import CryptoCurrencyInput from "../../input/CryptoCurrencyInput";
import { useModalStack } from "../../modal";
import ExpirationOptionSelect from "../components/ExpirationOptionSelect";
import MarketplaceConnectButton, { useMarketplaceAuth, } from "../components/MarketplaceConnectButton";
import MarketplacePopover from "../components/MarketplacePopover";
import MarketplaceSummaryCard from "../components/MarketplaceSummaryCard";
import RecentSales from "../components/RecentSales";
import RoyaltySettings from "../components/RoyaltySettings";
import { getMarketplaceFeeAddress } from "../fees";
import { useMarketplaceCollectionFeeQuery } from "../queries/marketplace.graphql.generated";
const ListModalHighlight = (props) => {
    var _a;
    const { address, chainId, editionId, onchainId } = props.collectionData;
    const editionIdNormalized = editionId || (onchainId === null || onchainId === void 0 ? void 0 : onchainId.split(":")[2]) || "0";
    const { data } = useMarketplaceCollectionFeeQuery({
        variables: {
            chainId,
            address,
            editionId: editionIdNormalized,
        },
    });
    const feeAddress = getMarketplaceFeeAddress(chainId);
    const marketplaceFeePercent = data === null || data === void 0 ? void 0 : data.getMarketplaceCollectionFee.fee;
    const feesBps = feeAddress && marketplaceFeePercent != null
        ? [`${getMarketplaceFeeAddress(chainId)}:${marketplaceFeePercent * 100}`]
        : undefined;
    return (_jsx(Stack, { pt: 16, spacing: 40, children: _jsx(ListModal.Custom, { open: true, chainId: props.collectionData.chainId, collectionId: (_a = props.collectionData) === null || _a === void 0 ? void 0 : _a.address, tokenId: props.tokenId, oracleEnabled: true, feesBps: feesBps, children: (listProps) => _jsx(ListModalInner, { ...listProps, ...props }) }) }));
};
const btnTexts = {
    ["Approve NFT contract"]: "Approving the token contract",
    ["Authorize listing"]: "Creating a listing",
};
const ListModalInner = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const { token, expirationOption, expirationOptions, listStep, transactionError, setExpirationOption, 
    // unapprovedMarketplaces,
    // isFetchingUnapprovedMarketplaces,
    listToken, stepData, collection, setPrice, price, royaltyBps, marketplace, currency, src, collectionData, listingData, tokenId, setQuantity, quantityAvailable, quantity, onCompleted, } = props;
    const is1155 = (collectionData === null || collectionData === void 0 ? void 0 : collectionData.standard) === NftContractStandard.ERC1155;
    const getNewTxnId = () => `txn-${Date.now()}`;
    const [txnId, setTxnId] = useState(getNewTxnId());
    const txHash = (_b = (_a = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep.items) === null || _a === void 0 ? void 0 : _a[0].txHashes) === null || _b === void 0 ? void 0 : _b[0].txHash;
    const transactionDispatch = useTransactionsDispatch();
    const [customRoyalty, setCustomRoyalty] = useState(royaltyBps || 0);
    const symbol = currency.symbol;
    const [error, setError] = useState();
    const { setModalProps } = useModalStack();
    const { isFullyAuthenticated, walletAddress } = useMarketplaceAuth();
    const tokenLink = tokenId
        ? getDetailPageUrl(collectionData, tokenId)
        : "";
    const client = useReservoirClient();
    const rendererChain = client === null || client === void 0 ? void 0 : client.currentChain();
    const { chain: activeChain } = useAccount();
    const config = useConfig();
    const handleList = async () => {
        var _a;
        if (!rendererChain)
            throw new Error("No chain found");
        if (rendererChain.id !== (activeChain === null || activeChain === void 0 ? void 0 : activeChain.id)) {
            const newChain = await switchChain(config, {
                chainId: rendererChain.id,
            });
            if (rendererChain.id !== (newChain === null || newChain === void 0 ? void 0 : newChain.id)) {
                throw new Error("Mismatching chainIds");
            }
        }
        setError("");
        if (!txnId) {
            return;
        }
        transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
            type: "START_TRANSACTION",
            payload: {
                id: txnId,
                entityId: (_a = collection === null || collection === void 0 ? void 0 : collection.id) !== null && _a !== void 0 ? _a : "",
                transactionType: TransactionType.EVM_APPROVE_TRANSFER,
                collectionType: _CollectionType.GenerativeSeries,
            },
        });
        listToken({ royaltyBps: customRoyalty });
    };
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        if (txHash && txnId && ((_a = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep) === null || _a === void 0 ? void 0 : _a.id) === "nft-approval") {
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_ARGS",
                payload: {
                    id: txnId,
                    args: (_d = (_c = (_b = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.data,
                    metadata: {
                        collectionId: (_e = collectionData.id) !== null && _e !== void 0 ? _e : "",
                        nftContractAddress: (_f = collectionData.address) !== null && _f !== void 0 ? _f : "",
                        tokenId: tokenId !== null && tokenId !== void 0 ? tokenId : "",
                    },
                },
            });
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id: txnId,
                    hash: txHash,
                },
            });
            setTxnId(null);
        }
    }, [stepData]);
    const btnText = error
        ? "Create listing"
        : btnTexts[(_c = stepData === null || stepData === void 0 ? void 0 : stepData.currentStep) === null || _c === void 0 ? void 0 : _c.action] ||
            "Create listing";
    const theme = useMantineTheme();
    const floorPrice = (_d = collection === null || collection === void 0 ? void 0 : collection.floorAsk) === null || _d === void 0 ? void 0 : _d.price;
    const floorPriceText = (_jsx(FormatCrypto, { size: "sm", amount: (_e = floorPrice === null || floorPrice === void 0 ? void 0 : floorPrice.amount) === null || _e === void 0 ? void 0 : _e.native, symbol: symbol }));
    const numberInputDescription = (_jsxs(Flex, { sx: { whiteSpace: "pre" }, children: ["Floor price: ", floorPriceText] }));
    const actionButton = useMemo(() => {
        var _a;
        if (!isFullyAuthenticated)
            return _jsx(MarketplaceConnectButton, {});
        if (listStep === ListStep.Complete) {
            return (_jsxs(_Fragment, { children: [_jsx(EmailUpdates, { imageUrl: (_a = collection === null || collection === void 0 ? void 0 : collection.image) !== null && _a !== void 0 ? _a : "", autoOpen: true, description: "Maximize your selling potential\u2014sign up for email notifications. Stay informed about your listing's offers and get instant offer alerts." }), _jsxs(Grid, { children: [_jsx(Grid.Col, { span: 6, children: _jsx(Button, { component: "a", size: "xl", w: "100%", color: SECONDARY_COLOR, href: tokenLink, children: "View token" }) }), _jsx(Grid.Col, { span: 6, children: _jsx(Button, { component: "a", size: "xl", w: "100%", href: USER_ROUTES.user.marketplace.listings.replace(":slug", walletAddress !== null && walletAddress !== void 0 ? walletAddress : ""), target: "_blank", children: "Manage listings" }) })] })] }));
        }
        return (_jsx(Button, { size: "xl", onClick: handleList, disabled: !price || price === "0", loading: listStep === ListStep.Listing && !transactionError, children: btnText }));
    }, [
        isFullyAuthenticated,
        listStep,
        btnText,
        price,
        transactionError,
        handleList,
    ]);
    useEffect(() => {
        if (listStep === ListStep.Complete) {
            onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted();
            setModalProps({
                title: (_jsxs(Text, { size: "md", fw: WEIGHT_BOLD, children: [token ? "Token" : "Collection", " listed"] })),
            });
        }
    }, [listStep]);
    useEffect(() => {
        if (royaltyBps != null) {
            setCustomRoyalty(royaltyBps);
        }
    }, [royaltyBps]);
    useEffect(() => {
        var _a;
        if (!transactionError) {
            setError("");
        }
        else {
            if ((_a = transactionError === null || transactionError === void 0 ? void 0 : transactionError.message) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes("balance too low")) {
                setError("Insufficient balance, add funds then try again");
            }
            if (transactionError === null || transactionError === void 0 ? void 0 : transactionError.message.toLowerCase().includes("user rejected the request")) {
                setError("Request cancelled");
            }
            else {
                setError(transactionError
                    .shortMessage || "Ooops, something went wrong");
            }
        }
    }, [transactionError]);
    const totalEarning = (1 -
        (((_f = marketplace === null || marketplace === void 0 ? void 0 : marketplace.fee) === null || _f === void 0 ? void 0 : _f.percent) || 0) / 100 -
        (customRoyalty || 0) * 0.0001) *
        Number(price) *
        (is1155 ? quantity : 1);
    const royalties = Number(price) * (customRoyalty || 0) * 0.0001;
    const marketplaceFee = Number(price) * (((_g = marketplace === null || marketplace === void 0 ? void 0 : marketplace.fee) === null || _g === void 0 ? void 0 : _g.percent) / 100) * (quantity !== null && quantity !== void 0 ? quantity : 1);
    const validUntil = (_j = (_h = listingData[0]) === null || _h === void 0 ? void 0 : _h.listing) === null || _j === void 0 ? void 0 : _j.expirationTime;
    return (_jsxs(_Fragment, { children: [_jsx(MarketplaceSummaryCard, { symbol: symbol, tokenId: tokenId, collectionName: collectionData.name, subtext: listStep !== ListStep.Complete && (_jsxs(Text, { size: "xs", children: ["Creator royalties: ", customRoyalty ? customRoyalty / 100 : "0", "%"] })), quantity: quantity, imageUrl: src, price: price, floorPrice: (_k = floorPrice === null || floorPrice === void 0 ? void 0 : floorPrice.amount) === null || _k === void 0 ? void 0 : _k.native, validUntil: validUntil ? parseInt(validUntil) * 1000 : undefined }), listStep !== ListStep.Complete && (_jsxs(_Fragment, { children: [is1155 && (_jsxs(Stack, { spacing: 4, children: [_jsx(NumberInput, { label: "Number of tokens to list", placeholder: "1", onChange: (value) => setQuantity(parseInt(String(value))), defaultValue: 1, value: quantity, min: 1, max: quantityAvailable, styles: {
                                    input: {
                                        paddingRight: 24,
                                    },
                                    rightSection: {
                                        paddingRight: 0,
                                    },
                                } }), _jsxs(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: ["You can list up to ", quantityAvailable, " tokens"] })] })), collectionData.chainId && marketplace && (_jsx(CryptoCurrencyInput, { chainId: collectionData.chainId, missingChainIdPlaceholder: "Unknown chain", label: _jsxs(MarketplacePopover, { text: "Prices are final after listing. To change the price create a new listing.", children: ["List price ", is1155 && "per token"] }), value: parseFloat(price || "0"), onChange: (amount) => setPrice(String(amount || 0)), precision: 5, description: numberInputDescription, placeholder: "0.1" })), _jsx(ExpirationOptionSelect, { expirationOption: expirationOption, expirationOptions: expirationOptions, setExpirationOption: setExpirationOption, label: "Listing duration" }), _jsx(RoyaltySettings, { royaltyBps: customRoyalty, setRoyaltyBps: setCustomRoyalty, defaultBps: royaltyBps }), _jsx(RecentSales, { name: collectionData.name, isCollectionBid: false, address: collectionData.address }), _jsxs(Stack, { spacing: 0, children: [_jsxs(Flex, { justify: "space-between", align: "center", children: [_jsxs(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: ["Listing price ", is1155 && "per token"] }), _jsx(FormatCrypto, { size: "xs", color: TEXT_COLOR.SECONDARY, amount: parseFloat(price || "0"), symbol: symbol })] }), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(MarketplacePopover, { text: `Highlight will charge ${((_l = marketplace === null || marketplace === void 0 ? void 0 : marketplace.fee) === null || _l === void 0 ? void 0 : _l.percent)
                                            ? `a ${(_m = marketplace === null || marketplace === void 0 ? void 0 : marketplace.fee) === null || _m === void 0 ? void 0 : _m.percent}%`
                                            : "no"} fee for this listing.`, url: EXTERNAL_URL.MARKETPLACE_FEE_EXPLANATION, children: _jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Marketplace fee" }) }), marketplaceFee ? (_jsx(FormatCrypto, { amount: marketplaceFee, symbol: symbol, size: "xs", color: TEXT_COLOR.SECONDARY })) : (_jsxs(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: [((_o = marketplace === null || marketplace === void 0 ? void 0 : marketplace.fee) === null || _o === void 0 ? void 0 : _o.percent) != null
                                                ? (_p = marketplace === null || marketplace === void 0 ? void 0 : marketplace.fee) === null || _p === void 0 ? void 0 : _p.percent
                                                : 2.5, "%"] }))] }), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(MarketplacePopover, { text: customRoyalty
                                            ? "100% of creator royalties are paid to the creator of this collection."
                                            : "This creator hasn't enabled creator royalties.", url: customRoyalty
                                            ? EXTERNAL_URL.ROYALTY_FEE_EXPLANATION
                                            : undefined, children: _jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Creator royalties" }) }), royalties ? (_jsx(FormatCrypto, { amount: royalties, symbol: symbol, size: "xs", color: TEXT_COLOR.SECONDARY })) : (_jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: customRoyalty != null ? `${customRoyalty / 100}%` : "— %" }))] }), _jsx(Divider, { my: "sm", size: 0.5 }), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(Text, { size: "lg", fw: WEIGHT_BOLD, children: "Total earnings" }), _jsx(FormatCrypto, { amount: totalEarning, symbol: symbol, size: "lg", fw: WEIGHT_BOLD })] })] })] })), _jsxs(Stack, { spacing: 8, sx: { overflow: "hidden" }, children: [_jsx(Space, { h: 8, mx: -16, sx: {
                            borderTop: `1px solid ${theme.colors.divider[0]}`,
                            width: "auto !important",
                        } }), actionButton, error && (_jsx(Text, { size: "xs", align: "center", color: theme.colors.errorStatus[0], children: error }))] })] }));
};
export default ListModalHighlight;
