import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import FormatCrypto from "@hl/base-components/lib/FormatCrypto";
import useMantineMediaQueries from "@hl/base-components/lib/hooks/useMantineMediaQueries";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Flex, Group, Select, Text, UnstyledButton, useMantineTheme, } from "@mantine/core";
import { extend } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import { getCurrencySymbol } from "../../../utils/currency";
extend(timezone);
extend(advancedFormat);
extend(customParseFormat);
const ExpirationOptionSelect = ({ traitOptions, trait, setTrait, label, collectionSize, }) => {
    var _a;
    const { isExtraSmall } = useMantineMediaQueries({ withInitialValues: true });
    const theme = useMantineTheme();
    return (_jsx(Select, { styles: {
            root: { flexGrow: !isExtraSmall ? 0 : 1 },
            separatorLabel: {
                fontSize: 14,
                color: `${theme.colors.secondaryText[0]} !important`,
                fontWeight: WEIGHT_BOLD,
            },
        }, rightSectionProps: (trait === null || trait === void 0 ? void 0 : trait.value) ? { style: { pointerEvents: "all" } } : undefined, allowDeselect: true, clearable: true, rightSection: (trait === null || trait === void 0 ? void 0 : trait.value) ? (_jsx(UnstyledButton, { onClick: () => setTrait(), sx: {
                fontSize: 12,
                flexShrink: 0,
                background: theme.colors.primaryBackground[0],
            }, children: "clear" })) : undefined, filter: (value, item) => {
            var _a, _b;
            if (!value)
                return true;
            return (((_a = item.value) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(value.toLowerCase())) ||
                ((_b = item.group) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(value.toLowerCase())) ||
                false);
        }, filterDataOnExactSearchMatch: false, searchable: true, label: label, placeholder: `All traits`, data: traitOptions.map((tr) => {
            var _a, _b, _c, _d;
            return ({
                ...tr,
                label: (_a = tr === null || tr === void 0 ? void 0 : tr.value) !== null && _a !== void 0 ? _a : "",
                value: (_b = tr === null || tr === void 0 ? void 0 : tr.value) !== null && _b !== void 0 ? _b : "",
                key: `${(_c = tr === null || tr === void 0 ? void 0 : tr.key) !== null && _c !== void 0 ? _c : ""}${(_d = tr === null || tr === void 0 ? void 0 : tr.value) !== null && _d !== void 0 ? _d : ""}`,
            });
        }), itemComponent: (props) => (_jsx(SelectItem, { ...props, selected: props.value === (trait === null || trait === void 0 ? void 0 : trait.value), collectionSize: collectionSize })), value: (_a = trait === null || trait === void 0 ? void 0 : trait.value) !== null && _a !== void 0 ? _a : "", onChange: (value) => {
            const selectedOption = traitOptions.find((trait) => (trait === null || trait === void 0 ? void 0 : trait.value) === value);
            setTrait(selectedOption);
        } }));
};
const SelectItem = ({ value, floorAskPriceFull, collectionSize, count, ...others }) => {
    var _a, _b, _c, _d, _e;
    const symbol = (_b = (_a = floorAskPriceFull === null || floorAskPriceFull === void 0 ? void 0 : floorAskPriceFull.currency) === null || _a === void 0 ? void 0 : _a.symbol) !== null && _b !== void 0 ? _b : getCurrencySymbol((_d = (_c = floorAskPriceFull === null || floorAskPriceFull === void 0 ? void 0 : floorAskPriceFull.currency) === null || _c === void 0 ? void 0 : _c.chainId) !== null && _d !== void 0 ? _d : 1);
    const rarity = count && collectionSize
        ? new Intl.NumberFormat("en-IN", {
            maximumFractionDigits: 0,
            style: "percent",
        }).format(count / collectionSize)
        : undefined;
    return (_jsx(Flex, { ...others, children: _jsxs(Flex, { justify: "space-between", gap: 8, w: "100%", children: [_jsxs(Group, { spacing: 4, children: [_jsx(Text, { size: "sm", fw: others.selected === value ? WEIGHT_BOLD : undefined, children: value }), _jsxs(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, fw: others.selected === value ? WEIGHT_BOLD : undefined, children: ["(", rarity, ")"] })] }), floorAskPriceFull && (_jsx(FormatCrypto, { amount: (_e = floorAskPriceFull === null || floorAskPriceFull === void 0 ? void 0 : floorAskPriceFull.amount) === null || _e === void 0 ? void 0 : _e.native, size: "sm", symbol: symbol, mr: 4 }))] }) }));
};
export default ExpirationOptionSelect;
