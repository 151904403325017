import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useEffect, useState, useCallback, useMemo, } from "react";
import { useDebouncedState } from "@mantine/hooks";
import { useReservoirClient, useTokens, useCoinConversion, } from "@reservoir0x/reservoir-kit-ui";
import { axios, customChains, } from "@reservoir0x/reservoir-sdk";
import { formatEther } from "ethers/lib/utils";
import { formatUnits, parseUnits } from "viem";
import * as allChains from "viem/chains";
import { useAccount, useConfig, useWalletClient } from "wagmi";
import { switchChain } from "wagmi/actions";
export var AcceptBidStep;
(function (AcceptBidStep) {
    AcceptBidStep[AcceptBidStep["Checkout"] = 0] = "Checkout";
    AcceptBidStep[AcceptBidStep["Auth"] = 1] = "Auth";
    AcceptBidStep[AcceptBidStep["ApproveMarketplace"] = 2] = "ApproveMarketplace";
    AcceptBidStep[AcceptBidStep["Finalizing"] = 3] = "Finalizing";
    AcceptBidStep[AcceptBidStep["Complete"] = 4] = "Complete";
    AcceptBidStep[AcceptBidStep["Unavailable"] = 5] = "Unavailable";
    AcceptBidStep[AcceptBidStep["TokenSwap"] = 6] = "TokenSwap";
})(AcceptBidStep || (AcceptBidStep = {}));
export const AcceptBidModalRenderer = ({ open, tokens, chainId, normalizeRoyalties, children, walletClient, feesOnTopBps, feesOnTopCustom, currency, }) => {
    var _a, _b, _c;
    const [stepData, setStepData] = useState(null);
    const [prices, setPrices] = useState([]);
    const [acceptBidStep, setAcceptBidStep] = useState(AcceptBidStep.Checkout);
    const [quantity, setQuantity] = useDebouncedState(1, 300);
    const [transactionError, setTransactionError] = useState();
    const [txHash, setTxHash] = useState(null);
    const client = useReservoirClient();
    const currentChain = client === null || client === void 0 ? void 0 : client.currentChain();
    const config = useConfig();
    const rendererChain = chainId
        ? (client === null || client === void 0 ? void 0 : client.chains.find(({ id }) => id === chainId)) || currentChain
        : currentChain;
    const wagmiChain = Object.values({
        ...allChains,
        ...customChains,
    }).find(({ id }) => (rendererChain === null || rendererChain === void 0 ? void 0 : rendererChain.id) === id);
    const { data: wagmiWallet } = useWalletClient({ chainId: rendererChain === null || rendererChain === void 0 ? void 0 : rendererChain.id });
    const wallet = walletClient || wagmiWallet;
    const blockExplorerBaseUrl = ((_b = (_a = wagmiChain === null || wagmiChain === void 0 ? void 0 : wagmiChain.blockExplorers) === null || _a === void 0 ? void 0 : _a.default) === null || _b === void 0 ? void 0 : _b.url) || "https://etherscan.io";
    const [isFetchingBidPath, setIsFetchingBidPath] = useState(false);
    const [bidsPath, setBidsPath] = useState(null);
    const [feesOnTop, setFeesOnTop] = useState(null);
    const _tokenIds = tokens.map((token) => {
        const contract = ((token === null || token === void 0 ? void 0 : token.collectionId) || "").split(":")[0];
        return `${contract}:${token.tokenId}`;
    });
    const { data: tokensData, mutate: mutateTokens, isValidating: isFetchingTokenData, } = useTokens(open &&
        _tokenIds &&
        _tokenIds.length > 0 && {
        tokens: _tokenIds,
        normalizeRoyalties,
    }, {
        revalidateFirstPage: true,
    }, rendererChain === null || rendererChain === void 0 ? void 0 : rendererChain.id);
    const enhancedTokens = useMemo(() => {
        const tokensDataMap = tokensData.reduce((map, data) => {
            var _a, _b;
            map[`${(_a = data.token) === null || _a === void 0 ? void 0 : _a.contract}:${(_b = data.token) === null || _b === void 0 ? void 0 : _b.tokenId}`] = data;
            return map;
        }, {});
        const tokensBidPathMap = (bidsPath === null || bidsPath === void 0 ? void 0 : bidsPath.reduce((map, path) => {
            const key = `${path.contract}:${path.tokenId}`;
            const mapPath = map[key];
            if (!mapPath) {
                map[key] = [path];
            }
            else {
                mapPath.push(path);
            }
            return map;
        }, {})) ||
            {};
        return tokens.reduce((enhancedTokens, token) => {
            var _a, _b, _c;
            const contract = token.collectionId.split(":")[0];
            const dataMapKey = `${contract}:${token.tokenId}`;
            const tokenData = tokensDataMap[dataMapKey];
            const bidIds = ((_a = token.bidIds) === null || _a === void 0 ? void 0 : _a.filter((bidId) => bidId.length > 0)) || [];
            const bidsPath = tokensBidPathMap[dataMapKey] ? tokensBidPathMap[dataMapKey] : [];
            if (!bidIds.length) {
                enhancedTokens.push({
                    ...token,
                    bidIds: ((_c = (_b = tokenData === null || tokenData === void 0 ? void 0 : tokenData.market) === null || _b === void 0 ? void 0 : _b.topBid) === null || _c === void 0 ? void 0 : _c.id)
                        ? [tokenData.market.topBid.id]
                        : [],
                    tokenData,
                    bidsPath,
                });
            }
            else {
                enhancedTokens.push({
                    ...token,
                    bidIds: token.bidIds || [],
                    tokenData,
                    bidsPath,
                });
            }
            return enhancedTokens;
        }, []);
    }, [tokensData, tokens, bidsPath]);
    const bidTokenMap = useMemo(() => enhancedTokens.reduce((map, token) => {
        token.bidIds.forEach((bidId) => {
            map[bidId] = token;
        });
        return map;
    }, {}), [enhancedTokens]);
    const fetchBidsPath = useCallback((tokens) => {
        if (!wallet || !client) {
            setIsFetchingBidPath(false);
            return;
        }
        setIsFetchingBidPath(true);
        const options = {
            onlyPath: true,
            ...(currency && { currency }),
            partial: true,
        };
        if (normalizeRoyalties !== undefined) {
            options.normalizeRoyalties = normalizeRoyalties;
        }
        const items = tokens === null || tokens === void 0 ? void 0 : tokens.reduce((items, token) => {
            if (tokens) {
                const contract = token.collectionId.split(":")[0];
                const bids = token.bidIds
                    ? token.bidIds.filter((bid) => bid.length > 0)
                    : [];
                if (bids && bids.length > 0) {
                    bids.forEach((bidId) => {
                        items.push({
                            orderId: bidId,
                            token: `${contract}:${token.tokenId}`,
                            quantity,
                        });
                    });
                }
                else {
                    items.push({
                        token: `${contract}:${token.tokenId}`,
                        quantity,
                    });
                }
            }
            return items;
        }, []);
        const acceptOfferParams = {
            chainId: rendererChain === null || rendererChain === void 0 ? void 0 : rendererChain.id,
            items: items,
            wallet,
            options,
            precheck: true,
            onProgress: () => {
                return;
            },
        };
        client.actions
            .acceptOffer(acceptOfferParams)
            .then((data) => {
            if (feesOnTopBps || feesOnTopCustom) {
                const bidsPath = "path" in data
                    ? data["path"]
                    : null;
                if (bidsPath) {
                    let feesOnTop = [];
                    if (feesOnTopBps) {
                        const total = bidsPath.reduce((total, path) => {
                            return (total += BigInt(path.totalRawPrice || "0"));
                        }, BigInt(0));
                        feesOnTop = feesOnTopBps.map((feeOnTop) => {
                            const [recipient, fee] = feeOnTop.split(":");
                            return `${recipient}:${formatUnits((BigInt(fee) * total) / BigInt(10000), 0)}`;
                        });
                    }
                    else if (feesOnTopCustom) {
                        feesOnTop = feesOnTopCustom(bidsPath) || [];
                    }
                    if (feesOnTop) {
                        acceptOfferParams.options.feesOnTop = feesOnTop;
                        setFeesOnTop(feesOnTop);
                    }
                    return client.actions.acceptOffer(acceptOfferParams);
                }
            }
            else {
                return data;
            }
        })
            .then((data) => {
            // if ("path" in (data as { path?: SellPath })) {
            //   const qty = ((data as Execute)["path"] as SellPath)?.[0].quantity || 1;
            //   if (qty > quantity) {
            //     setQuantity(qty)
            //   }
            // }
            setBidsPath("path" in data
                ? data["path"]
                : null);
        })
            .catch((e) => {
            if (e.message.includes("No fillable orders")) {
                setTransactionError(e);
            }
        })
            .finally(() => {
            setIsFetchingBidPath(false);
        });
    }, [
        currency,
        client,
        wallet,
        rendererChain,
        normalizeRoyalties,
        feesOnTopBps,
        feesOnTopCustom,
        quantity,
    ]);
    useEffect(() => {
        if (open && client && quantity) {
            fetchBidsPath(tokens);
        }
    }, [client, tokens, open, quantity]);
    const currencySymbols = useMemo(() => Array.from(enhancedTokens.reduce((symbols, { bidsPath }) => {
        bidsPath.forEach(({ sellOutCurrencySymbol, currencySymbol }) => {
            if (sellOutCurrencySymbol) {
                symbols.add(sellOutCurrencySymbol);
            }
            if (currencySymbol) {
                symbols.add(currencySymbol);
            }
        });
        return symbols;
    }, new Set())).join(","), [enhancedTokens]);
    const conversions = useCoinConversion(open && currencySymbols.length > 0 ? "USD" : undefined, currencySymbols);
    const usdPrices = useMemo(() => conversions.reduce((map, price) => {
        map[price.symbol] = price;
        return map;
    }, {}), [conversions]);
    const { chain: activeWalletChain } = useAccount();
    const acceptBid = useCallback(async () => {
        setTransactionError(null);
        if (!wallet) {
            const error = new Error("Missing a wallet/signer");
            setTransactionError(error);
            throw error;
        }
        if ((rendererChain === null || rendererChain === void 0 ? void 0 : rendererChain.id) !== (activeWalletChain === null || activeWalletChain === void 0 ? void 0 : activeWalletChain.id)) {
            const newChain = await switchChain(config, {
                chainId: rendererChain === null || rendererChain === void 0 ? void 0 : rendererChain.id,
            });
            if ((rendererChain === null || rendererChain === void 0 ? void 0 : rendererChain.id) !== (newChain === null || newChain === void 0 ? void 0 : newChain.id)) {
                throw new Error("Mismatching chainIds");
            }
        }
        if (!bidsPath) {
            const error = new Error("Missing bids to accept");
            setTransactionError(error);
            throw error;
        }
        if (!client) {
            const error = new Error("ReservoirClient was not initialized");
            setTransactionError(error);
            setTransactionError(null);
            throw error;
        }
        const options = {
            partial: true,
            ...(currency && { currency }),
        };
        if (normalizeRoyalties !== undefined) {
            options.normalizeRoyalties = normalizeRoyalties;
        }
        if (feesOnTop) {
            options.feesOnTop = feesOnTop;
        }
        setAcceptBidStep(AcceptBidStep.ApproveMarketplace);
        const items = bidsPath.map(({ orderId, tokenId, contract, quantity }) => ({
            orderId: orderId,
            token: `${contract}:${tokenId}`,
            quantity,
        }));
        const expectedPrice = {};
        for (const currency in prices) {
            expectedPrice[currency] = {
                amount: prices[currency].netAmount,
                raw: parseUnits(`${prices[currency].netAmount}`, prices[currency].currency.decimals || 18),
                currencyAddress: prices[currency].currency.contract,
                currencyDecimals: prices[currency].currency.decimals || 18,
            };
        }
        let hasError = false;
        client.actions
            .acceptOffer({
            chainId: rendererChain === null || rendererChain === void 0 ? void 0 : rendererChain.id,
            expectedPrice,
            wallet,
            items,
            onProgress: (steps, path) => {
                var _a, _b;
                if (!steps || hasError)
                    return;
                setBidsPath(path);
                const executableSteps = steps.filter((step) => step.items && step.items.length > 0);
                const stepCount = executableSteps.length;
                let currentStepItem;
                let currentStepIndex = 0;
                executableSteps.find((step, index) => {
                    var _a;
                    currentStepIndex = index;
                    currentStepItem = (_a = step.items) === null || _a === void 0 ? void 0 : _a.find((item) => item.status === "incomplete");
                    return currentStepItem;
                });
                const currentStep = currentStepIndex > -1
                    ? executableSteps[currentStepIndex]
                    : executableSteps[stepCount - 1];
                if (currentStepItem) {
                    setStepData({
                        totalSteps: stepCount,
                        currentStep,
                        currentStepItem,
                        steps,
                    });
                    if (currentStep.id === "auth") {
                        setAcceptBidStep(AcceptBidStep.Auth);
                    }
                    else if (currentStep.id === "nft-approval") {
                        setAcceptBidStep(AcceptBidStep.ApproveMarketplace);
                    }
                    else if (currentStep.id === "sale") {
                        if ((_a = currentStep.items) === null || _a === void 0 ? void 0 : _a.every((item) => item.txHashes !== undefined)) {
                            setAcceptBidStep(AcceptBidStep.Finalizing);
                        }
                        else {
                            setAcceptBidStep(AcceptBidStep.ApproveMarketplace);
                        }
                    }
                    else if (currentStep.id === "swap") {
                        setAcceptBidStep(AcceptBidStep.TokenSwap);
                    }
                }
                else if (executableSteps.every((step) => {
                    var _a;
                    return !step.items ||
                        step.items.length == 0 ||
                        ((_a = step.items) === null || _a === void 0 ? void 0 : _a.every((item) => item.status === "complete"));
                })) {
                    setAcceptBidStep(AcceptBidStep.Complete);
                    const lastStepItem = currentStep.items
                        ? currentStep.items[((_b = currentStep.items) === null || _b === void 0 ? void 0 : _b.length) - 1]
                        : undefined;
                    if (lastStepItem) {
                        setStepData({
                            totalSteps: stepCount,
                            steps,
                            currentStep,
                            currentStepItem: lastStepItem,
                        });
                    }
                }
            },
            options,
        })
            .catch((e) => {
            hasError = true;
            setTransactionError(e);
            setAcceptBidStep(AcceptBidStep.Checkout);
            setStepData(null);
            fetchBidsPath(tokens);
            mutateTokens();
        });
    }, [
        currency,
        config,
        bidsPath,
        bidTokenMap,
        rendererChain,
        client,
        wallet,
        prices,
        feesOnTop,
        mutateTokens,
        quantity,
    ]);
    useEffect(() => {
        if (bidsPath && bidsPath.length > 0) {
            const prices = bidsPath.reduce((map, { quote, sellOutCurrency, sellOutCurrencySymbol, sellOutQuote, currency, currencyDecimals, currencySymbol, builtInFees, feesOnTop, totalPrice, totalRawPrice, }) => {
                const netAmount = sellOutQuote || quote || 0;
                const amount = totalPrice || parseFloat(formatEther(totalRawPrice || "0")) || 0;
                let royalty = 0;
                let marketplaceFee = 0;
                let referralFee = 0;
                if (sellOutCurrency && sellOutCurrencySymbol) {
                    builtInFees === null || builtInFees === void 0 ? void 0 : builtInFees.forEach((fee) => {
                        switch (fee.kind) {
                            case "marketplace": {
                                marketplaceFee += parseFloat(formatEther((fee === null || fee === void 0 ? void 0 : fee.rawAmount) || "0"));
                                break;
                            }
                            case "royalty": {
                                royalty += parseFloat(formatEther((fee === null || fee === void 0 ? void 0 : fee.rawAmount) || "0"));
                                break;
                            }
                        }
                    });
                    feesOnTop === null || feesOnTop === void 0 ? void 0 : feesOnTop.forEach((fee) => {
                        switch (fee.kind) {
                            case "royalty": {
                                royalty += parseFloat(formatEther((fee === null || fee === void 0 ? void 0 : fee.rawAmount) || "0"));
                                break;
                            }
                            case "marketplace":
                            default: {
                                referralFee += parseFloat(formatEther((fee === null || fee === void 0 ? void 0 : fee.rawAmount) || "0"));
                                break;
                            }
                        }
                    });
                    if (!map[sellOutCurrencySymbol]) {
                        map[sellOutCurrencySymbol] = {
                            netAmount: netAmount - referralFee,
                            amount,
                            currency: {
                                contract: sellOutCurrency,
                                symbol: sellOutCurrencySymbol,
                                decimals: currencyDecimals,
                            },
                            royalty,
                            marketplaceFee,
                            feesOnTop: referralFee,
                        };
                    }
                    else if (map[sellOutCurrencySymbol]) {
                        map[sellOutCurrencySymbol].netAmount += netAmount - referralFee;
                        map[sellOutCurrencySymbol].amount += amount;
                        map[sellOutCurrencySymbol].royalty += royalty;
                        map[sellOutCurrencySymbol].marketplaceFee += marketplaceFee;
                        map[sellOutCurrencySymbol].feesOnTop += referralFee;
                    }
                }
                else if (currency && currencySymbol) {
                    builtInFees === null || builtInFees === void 0 ? void 0 : builtInFees.forEach((fee) => {
                        switch (fee.kind) {
                            case "marketplace": {
                                marketplaceFee += parseFloat(formatEther((fee === null || fee === void 0 ? void 0 : fee.rawAmount) || "0"));
                                break;
                            }
                            case "royalty": {
                                royalty += parseFloat(formatEther((fee === null || fee === void 0 ? void 0 : fee.rawAmount) || "0"));
                                break;
                            }
                        }
                    });
                    feesOnTop === null || feesOnTop === void 0 ? void 0 : feesOnTop.forEach((fee) => {
                        switch (fee.kind) {
                            case "royalty": {
                                royalty += parseFloat(formatEther((fee === null || fee === void 0 ? void 0 : fee.rawAmount) || "0"));
                                break;
                            }
                            case "marketplace":
                            default: {
                                referralFee += parseFloat(formatEther((fee === null || fee === void 0 ? void 0 : fee.rawAmount) || "0"));
                                break;
                            }
                        }
                    });
                    if (!map[currencySymbol]) {
                        map[currencySymbol] = {
                            netAmount: netAmount - referralFee,
                            amount,
                            currency: {
                                contract: currency,
                                symbol: currencySymbol,
                                decimals: currencyDecimals,
                            },
                            royalty,
                            marketplaceFee,
                            feesOnTop: referralFee,
                        };
                    }
                    else if (map[currencySymbol]) {
                        map[currencySymbol].netAmount += netAmount - referralFee;
                        map[currencySymbol].amount += amount;
                        map[currencySymbol].royalty += royalty;
                        map[currencySymbol].marketplaceFee += marketplaceFee;
                        map[currencySymbol].feesOnTop += referralFee;
                    }
                }
                return map;
            }, {});
            setPrices(Object.values(prices));
            if (acceptBidStep === AcceptBidStep.Unavailable) {
                setAcceptBidStep(AcceptBidStep.Checkout);
            }
        }
        else if (!isFetchingBidPath) {
            setPrices([]);
            setAcceptBidStep(AcceptBidStep.Unavailable);
        }
    }, [client, bidsPath, isFetchingBidPath]);
    const swapCurrency = useMemo(() => {
        const bidPath = bidsPath === null || bidsPath === void 0 ? void 0 : bidsPath[0];
        if (bidPath && bidPath.sellOutCurrency) {
            return {
                contract: bidPath.sellOutCurrency,
                decimals: bidPath.sellOutCurrencyDecimals,
                symbol: bidPath.sellOutCurrencySymbol,
            };
        }
        else
            return null;
    }, [bidsPath, currency]);
    const { address } = useAccount();
    useEffect(() => {
        if (!open) {
            setAcceptBidStep(AcceptBidStep.Checkout);
            setTxHash(null);
            setStepData(null);
            setTransactionError(null);
        }
    }, [open]);
    open
        ? (axios.defaults.headers.common["x-rkui-context"] =
            "acceptBidModalRenderer")
        : (_c = axios.defaults.headers.common) === null || _c === void 0 ? true : delete _c["x-rkui-context"];
    return (_jsx(_Fragment, { children: children({
            swapCurrency,
            loading: isFetchingBidPath || isFetchingTokenData,
            tokensData: enhancedTokens,
            acceptBidStep,
            transactionError,
            txHash,
            usdPrices,
            prices,
            address,
            blockExplorerBaseUrl,
            acceptBid,
            setAcceptBidStep,
            stepData,
            setQuantity,
            quantity,
        }) }));
};
