export default function usePercentDifference(num1, num2, compact) {
    const first = parseFloat(String(num1 || 0));
    const second = parseFloat(String(num2 || 0));
    const diff = first && second ? (second - first) / second : null;
    const isDiffPositive = first > second;
    const diffFormatted = diff != null
        ? new Intl.NumberFormat("en-US", {
            style: "percent",
            maximumFractionDigits: 2,
        }).format(Math.abs(diff))
        : null;
    const diffWording = diffFormatted
        ? `${diffFormatted} ${isDiffPositive ? "above" : "below"}${compact ? "" : " floor"}`
        : null;
    return {
        diff,
        isDiffPositive,
        diffFormatted,
        diffWording,
    };
}
