import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { createContext, useContext, useEffect, useState, } from "react";
import { ReservoirKitProvider } from "@reservoir0x/reservoir-kit-ui";
import { useAccount } from "wagmi";
import { envConfig } from "../../config";
import supportedChains from "./chains";
import getMarketplaceFeeConfig from "./fees";
export const MarketplaceContext = createContext(null);
export const useMarketplaceSettings = () => useContext(MarketplaceContext);
const MarketplaceProvider = ({ children }) => {
    var _a, _b;
    const account = useAccount();
    const [networkId, setNetworkId] = useState((_a = account.chain) === null || _a === void 0 ? void 0 : _a.id);
    useEffect(() => {
        var _a, _b;
        if (!networkId && ((_a = account.chain) === null || _a === void 0 ? void 0 : _a.id)) {
            setNetworkId((_b = account.chain) === null || _b === void 0 ? void 0 : _b.id);
        }
    }, [networkId, (_b = account.chain) === null || _b === void 0 ? void 0 : _b.id]);
    // Example implementation: https://github.com/reservoirprotocol/marketplace-v2/blob/main/pages/_app.tsx
    const marketplaceFees = getMarketplaceFeeConfig(networkId);
    return (_jsx(MarketplaceContext.Provider, { value: { setNetworkId }, children: _jsx(ReservoirKitProvider, { options: {
                marketplaceFees,
                normalizeRoyalties: false,
                chains: supportedChains.map(({ reservoirBaseUrl, proxyApi, id, name, paymentTokens }) => {
                    return {
                        id,
                        name,
                        baseApiUrl: proxyApi
                            ? `${envConfig.marketplaceProxyApiUrl}${proxyApi}`
                            : reservoirBaseUrl,
                        active: networkId === id,
                        paymentTokens: paymentTokens,
                    };
                }),
                source: envConfig.marketplaceSource,
            }, children: children }) }));
};
export default MarketplaceProvider;
