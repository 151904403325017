import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState, } from "react";
import FormatCrypto from "@hl/base-components/lib/FormatCrypto";
import { SECONDARY_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR, UTILITY_COLOR, } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Box, Button, Card, Flex, Grid, Group, NumberInput, Space, Stack, Text, useMantineTheme, } from "@mantine/core";
import { BuyModal, BuyStep } from "@reservoir0x/reservoir-kit-ui";
import { ampli } from "../../../ampli";
import { _CollectionType, EvmTxSaleKind, NftContractStandard, TransactionType, } from "../../../apollo/graphql.generated";
import { getDetailPageUrl, USER_ROUTES } from "../../../config";
import { isTestnetNetwork, networkLookup } from "../../../utils/blockExplorer";
import { useTransactionsDispatch } from "../../evm-tx/TransactionContext";
import { ResizedImage } from "../../image";
import { useModalStack } from "../../modal";
import ListingSource from "../components/ListingSource";
import MarketplaceConnectButton, { useMarketplaceAuth, } from "../components/MarketplaceConnectButton";
const BuyModalHighlight = (props) => {
    var _a, _b;
    return (_jsx(Stack, { pt: 16, spacing: 40, children: _jsx(BuyModal.Custom, { open: true, onConnectWallet: () => {
                console.log("no wallet");
            }, normalizeRoyalties: false, token: `${(_b = (_a = props.collectionData) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.toLowerCase()}:${props.tokenId}`, chainId: props.collectionData.chainId, orderId: props.orderId, children: (buyProps) => _jsx(BuyModalInner, { ...props, ...buyProps }) }) }));
};
const BuyModalInner = (props) => {
    var _a, _b, _c, _d;
    const { listing, paymentCurrency, tokenId, src, collection, buyStep, transactionError, path: paths, addFundsLink, hasEnoughCurrency, onCompleted, averageUnitPrice, buyToken, collectionData, stepData, orderId, quantityAvailable, quantity, setQuantity, totalIncludingFees, } = props;
    const is1155 = (collectionData === null || collectionData === void 0 ? void 0 : collectionData.standard) === NftContractStandard.ERC1155;
    const getNewTxnId = () => `txn-${Date.now()}`;
    const [txnId, setTxnId] = useState(getNewTxnId());
    const txHash = useMemo(() => { var _a, _b, _c; return (_c = (_b = (_a = stepData === null || stepData === void 0 ? void 0 : stepData.currentStepItem) === null || _a === void 0 ? void 0 : _a.txHashes) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.txHash; }, [(_c = (_b = (_a = stepData === null || stepData === void 0 ? void 0 : stepData.currentStepItem) === null || _a === void 0 ? void 0 : _a.txHashes) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.txHash]);
    const transactionDispatch = useTransactionsDispatch();
    const path = paths === null || paths === void 0 ? void 0 : paths[0];
    const totalPrice = (path === null || path === void 0 ? void 0 : path.totalPrice) || 0;
    const theme = useMantineTheme();
    const [error, setError] = useState();
    const { setModalProps } = useModalStack();
    const { isFullyAuthenticated, walletAddress } = useMarketplaceAuth();
    const symbol = paymentCurrency === null || paymentCurrency === void 0 ? void 0 : paymentCurrency.symbol;
    const { popModal } = useModalStack();
    const tokenLink = tokenId
        ? getDetailPageUrl(collectionData, tokenId)
        : "";
    const buy = useCallback(() => {
        var _a;
        setError("");
        if (!txnId) {
            return;
        }
        transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
            type: "START_TRANSACTION",
            payload: {
                id: txnId,
                entityId: (_a = collection === null || collection === void 0 ? void 0 : collection.id) !== null && _a !== void 0 ? _a : "",
                transactionType: TransactionType.EVM_TOKEN_SALE,
                collectionType: _CollectionType.GenerativeSeries,
            },
        });
        buyToken();
    }, [buyToken, txnId]);
    useEffect(() => {
        var _a;
        if (txHash && txnId) {
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_ARGS",
                payload: {
                    id: txnId,
                    args: stepData === null || stepData === void 0 ? void 0 : stepData.currentStepItem.data,
                    metadata: {
                        collectionId: collectionData.id,
                        nftContractAddress: (_a = collectionData.address) !== null && _a !== void 0 ? _a : "",
                        reservoirOrderId: orderId !== null && orderId !== void 0 ? orderId : "",
                        saleKind: EvmTxSaleKind.LISTING,
                        tokenId: tokenId !== null && tokenId !== void 0 ? tokenId : "",
                    },
                },
            });
            transactionDispatch === null || transactionDispatch === void 0 ? void 0 : transactionDispatch({
                type: "UPDATE_TX_STATUS",
                payload: {
                    id: txnId,
                    hash: txHash,
                },
            });
            setTxnId(null);
        }
    }, [stepData]);
    const actionButtons = useMemo(() => {
        var _a;
        if (!isFullyAuthenticated)
            return _jsx(MarketplaceConnectButton, {});
        if (!hasEnoughCurrency) {
            return (_jsx(Button, { component: "a", href: addFundsLink, size: "xl", target: "_blank", disabled: true, children: "Add funds" }));
        }
        if (buyStep === BuyStep.Complete) {
            return (_jsxs(Grid, { children: [_jsx(Grid.Col, { span: 6, children: _jsx(Button, { component: "a", size: "xl", w: "100%", color: SECONDARY_COLOR, href: USER_ROUTES.user.marketplace.default.replace(":slug", walletAddress !== null && walletAddress !== void 0 ? walletAddress : ""), target: "_blank", children: "View collection" }) }), _jsx(Grid.Col, { span: 6, children: _jsx(Button, { component: "a", href: tokenLink, onClick: () => popModal(), w: "100%", size: "xl", children: "View token" }) })] }));
        }
        if ((walletAddress === null || walletAddress === void 0 ? void 0 : walletAddress.toLowerCase()) === ((_a = listing === null || listing === void 0 ? void 0 : listing.maker) === null || _a === void 0 ? void 0 : _a.toLowerCase())) {
            return (_jsx(Button, { size: "xl", disabled: true, children: "Can't buy your own listing" }));
        }
        if (listing && listing.status !== "active") {
            return (_jsx(Button, { size: "xl", disabled: true, children: "Listing inactive" }));
        }
        return (_jsx(Button, { size: "xl", onClick: buy, loading: buyStep === BuyStep.Approving, disabled: buyStep === BuyStep.Approving, children: "Buy" }));
    }, [
        isFullyAuthenticated,
        transactionError,
        buyStep,
        walletAddress,
        listing,
        buy,
        hasEnoughCurrency,
    ]);
    useEffect(() => {
        if (buyStep === BuyStep.Complete) {
            setModalProps({
                title: (_jsx(Text, { size: "md", fw: WEIGHT_BOLD, children: "Purchase complete" })),
            });
            onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted();
            if (ampli.isLoaded) {
                const network = networkLookup(collectionData.chainId);
                ampli.marketplacePurchase({
                    projectName: collectionData.name,
                    isTestnet: isTestnetNetwork(network.type),
                    ethValue: totalPrice,
                    contractAddress: collectionData.address,
                });
            }
        }
    }, [buyStep]);
    useEffect(() => {
        var _a;
        if (!transactionError) {
            setError("");
        }
        else {
            if ((_a = transactionError === null || transactionError === void 0 ? void 0 : transactionError.message) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes("balance too low")) {
                setError("Insufficient balance, add funds then try again");
            }
            if (transactionError === null || transactionError === void 0 ? void 0 : transactionError.message.toLowerCase().includes("user rejected the request")) {
                setError("Request cancelled");
            }
            else {
                setError(transactionError
                    .shortMessage || "Ooops, something went wrong");
            }
        }
    }, [transactionError]);
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { spacing: "xs", children: [buyStep !== BuyStep.Complete && (_jsx(Text, { fw: WEIGHT_BOLD, size: "sm", children: "Item" })), _jsx(Card, { children: _jsxs(Group, { noWrap: true, children: [_jsx(Box, { p: 8, bg: UTILITY_COLOR.TABLE_ROW_BACKGROUND, children: _jsx(ResizedImage, { src: src, width: 64 }) }), _jsxs(Flex, { justify: "space-between", align: { base: "flex-start", sm: "center" }, gap: "sm", direction: { base: "column", sm: "row" }, sx: { flexGrow: 1 }, children: [_jsxs(Stack, { spacing: 0, lh: 1.4, children: [_jsx(Text, { size: "lg", fw: WEIGHT_BOLD, children: `#${tokenId}` }), _jsx(Text, { size: "sm", tt: "uppercase", children: collection === null || collection === void 0 ? void 0 : collection.name }), buyStep === BuyStep.Complete && (_jsx(Text, { size: "xs", color: TEXT_COLOR.SECONDARY, children: "Owned by you" }))] }), buyStep !== BuyStep.Complete && (_jsx(FormatCrypto, { amount: totalPrice, symbol: symbol, size: "lg", fw: WEIGHT_BOLD }))] })] }) }), _jsx(ListingSource, { domain: (_d = listing === null || listing === void 0 ? void 0 : listing.source) === null || _d === void 0 ? void 0 : _d.domain, size: "xs", color: theme.colors.secondaryText[0], iconSize: 14 })] }), _jsxs(Stack, { spacing: 40, children: [buyStep !== BuyStep.Complete && (_jsxs(_Fragment, { children: [is1155 && (_jsxs(Stack, { spacing: 4, children: [_jsx(NumberInput, { label: "Number of tokens to buy", placeholder: "1", onChange: (value) => setQuantity(parseInt(String(value))), defaultValue: 1, value: quantity, min: 1, max: parseInt(String(quantityAvailable)), styles: {
                                            input: {
                                                paddingRight: 24,
                                            },
                                            rightSection: {
                                                paddingRight: 0,
                                            },
                                        } }), _jsxs(Text, { size: "xs", color: theme.colors.secondaryText[0], children: [parseInt(String(quantityAvailable)), " available"] })] })), _jsxs(Stack, { spacing: 8, children: [quantity > 1 && (_jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "Avg. unit price" }), _jsx(FormatCrypto, { amount: averageUnitPrice, symbol: symbol, size: "sm", fw: WEIGHT_BOLD })] })), _jsxs(Flex, { justify: "space-between", align: "center", children: [_jsx(Text, { size: "lg", fw: WEIGHT_BOLD, children: "Total" }), _jsx(FormatCrypto, { amount: totalIncludingFees, symbol: symbol, size: "lg", fw: WEIGHT_BOLD })] })] })] })), _jsxs(Stack, { spacing: 8, children: [_jsx(Space, { h: 8, mx: -16, sx: {
                                    borderTop: `1px solid ${theme.colors.divider[0]}`,
                                    width: "auto !important",
                                } }), _jsx(Stack, { sx: { overflow: "hidden" }, children: actionButtons }), error && (_jsx(Text, { size: "xs", align: "center", color: theme.colors.errorStatus[0], children: error }))] })] })] }));
};
export default BuyModalHighlight;
