import React from "react";

import { Browser, Grid01 } from "@hl/base-components/lib/assets/icons/HDS";
import { HlLogo } from "@hl/base-components/lib/assets/icons.generated";
import {
  OUTLINE_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
} from "@hl/base-components/lib/theme/button";
import { useAuth } from "@hl/shared-features/lib/features/auth/AuthContext";
import {
  buildTxUrl,
  getBlockExplorerName,
  getBlockExplorerLogo,
  networkLookup,
} from "@hl/shared-features/lib/utils/blockExplorer";
import {
  Anchor,
  Button,
  Group,
  Stack,
  Text,
  Container,
  Flex,
} from "@mantine/core";

import { getMintPageUrl, MINT_BASE_URL, USER_URL } from "~config";
import useReferral from "~features/share/useReferral";
import useMintState from "~hooks/useMintState";

import { GetSalePageCollectionQuery } from "../queries.graphql.generated";

const TokenRevealExplore = ({
  collection,
  isAnySeriesMint,
  txHash,
}: {
  collection: GetSalePageCollectionQuery["getPublicCollection"];
  isAnySeriesMint: boolean;
  txHash: string;
}) => {
  const { walletAddress } = useAuth();
  const { referrer } = useMintState();
  const { mintUrlWithReferrer } = useReferral();

  const network = networkLookup(collection.chainId).type;
  const explorerName = getBlockExplorerName(network);

  const explorerUrl = buildTxUrl(network, txHash);
  const browseLink = getMintPageUrl(collection, { marketplace: true });

  const blockExplorerLogo = getBlockExplorerLogo(network, {
    width: 16,
    height: 16,
  });

  const collectedLink = `${USER_URL.user.marketplace.default.replace(
    ":slug",
    walletAddress ?? ""
  )}`;

  const mintPageUrl = mintUrlWithReferrer(collection, referrer);

  return (
    <Container size={"xl"} w={"100%"} p={0}>
      <Stack spacing={32} align="center">
        <Flex
          direction={{ base: "column", md: "row" }}
          gap={16}
          align={{ base: "stretch", md: "center" }}
          justify={"center"}
          w={"100%"}
        >
          <Button
            component="a"
            size="xl"
            href={collectedLink}
            color={SECONDARY_COLOR}
            leftIcon={<Grid01 />}
            w={"100%"}
            maw={{ base: "100%", md: 400 }}
          >
            View my tokens
          </Button>

          {isAnySeriesMint && (
            <Button
              component="a"
              size="xl"
              href={MINT_BASE_URL + browseLink}
              color={PRIMARY_COLOR}
              leftIcon={<HlLogo />}
              w={"100%"}
              maw={{ base: "100%", md: 400 }}
            >
              View on marketplace
            </Button>
          )}

          <Button
            component="a"
            size="xl"
            href={mintPageUrl}
            color={OUTLINE_COLOR}
            leftIcon={<Browser />}
            w={"100%"}
            maw={{ base: "100%", md: 400 }}
          >
            Return to project page
          </Button>
        </Flex>

        <Anchor href={explorerUrl} target="_blank">
          <Group spacing={8}>
            {blockExplorerLogo}
            <Text size={"sm"}>View transaction on {explorerName}</Text>
          </Group>
        </Anchor>
      </Stack>
    </Container>
  );
};

export default TokenRevealExplore;
