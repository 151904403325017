import { reservoirChains } from "@reservoir0x/reservoir-sdk";
import { zoraTestnet } from "@wagmi/chains";
import { zeroAddress } from "viem";
import { baseSepolia } from "viem/chains";
import { arbitrum, mainnet, polygon, optimism, sepolia, scroll, zkSync, } from "wagmi/chains";
import { cyber, cyberReservoirChain, forma, formaReservoirChain, shape, shapeReservoirChain, zero, zeroReservoirChain, apechain, apechainReservoirChain, } from "../eth/providers/privy/customChains";
// import usdcContracts from "./usdcContracts";
//Chains that are missing from wagmi:
export const zora = {
    id: 7777777,
    name: "ZORA",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: {
        default: {
            http: ["https://rpc.zora.co"],
            webSocket: ["wss://rpc.zora.co"],
        },
        public: {
            http: ["https://rpc.zora.co"],
            webSocket: ["wss://rpc.zora.co"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "ZORA",
            url: "https://explorer.zora.energy",
        },
        default: {
            name: "ZORA",
            url: "https://explorer.zora.energy",
        },
    },
};
export const base = {
    id: 8453,
    name: "Base",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: {
        default: {
            http: ["https://developer-access-mainnet.base.org"],
        },
        public: {
            http: ["https://developer-access-mainnet.base.org"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "Basescan",
            url: "https://basescan.org",
        },
        default: {
            name: "BaseScan",
            url: "https://basescan.org",
        },
    },
};
const nativeCurrencyEtherBase = {
    contract: zeroAddress,
    symbol: "ETH",
    decimals: 18,
    coinGeckoId: "ethereum",
};
// const usdcCurrencyBase = {
//   contract: "",
//   symbol: "USDC",
//   decimals: 6,
//   coinGeckoId: "usd-coin",
// };
export const DefaultChain = {
    ...mainnet,
    // Any url to display the logo of the chain in light mode
    lightIconUrl: "",
    // Any url to display the logo of the chain in dark mode
    darkIconUrl: "",
    // The base url of the reservoir api, this is used in the app when
    // directly interacting with the reservoir indexer servers (in the api proxy for example)
    // or when prefetching server side rendered data
    reservoirBaseUrl: reservoirChains.mainnet.baseApiUrl,
    // Used on the client side portions of the marketplace that need an api key added
    // Prevents the api key from being leaked in the clientside requests
    // If you'd like to disable proxying you can just change the proxyApi to the reservoirBaseUrl
    // Doing so will omit the api key unless further changes are made
    proxyApi: "/reservoir/ethereum",
    // A prefix used in the asset specific routes on the app (tokens/collections)
    routePrefix: "ethereum",
    // Reservoir API key which you can generate at https://reservoir.tools/
    // This is a protected key and displays as 'undefined' on the browser
    // DO NOT add NEXT_PUBLIC to the key or you'll risk leaking it on the browser
    // apiKey: process.env.RESERVOIR_API_KEY,
    // Coingecko id, used to convert the chain's native prices to usd. Can be found here:
    // https://www.coingecko.com/en/api/documentation#operations-coins-get_coins_list
    coingeckoId: "ethereum",
    // collectionSetId: process.env.NEXT_PUBLIC_ETH_COLLECTION_SET_ID,
    // community: process.env.NEXT_PUBLIC_ETH_COMMUNITY,
    wssUrl: "wss://ws.reservoir.tools",
    listingCurrencies: [
        nativeCurrencyEtherBase,
        // {
        //   ...usdcCurrencyBase,
        //   contract: usdcContracts[mainnet.id],
        // },
    ],
    oracleBidsEnabled: true,
};
export default [
    DefaultChain,
    {
        ...sepolia,
        reservoirBaseUrl: reservoirChains.sepolia.baseApiUrl,
        proxyApi: "/reservoir/sepolia",
        routePrefix: "sepolia",
        coingeckoId: "ethereum",
        wssUrl: "wss://ws-sepolia.reservoir.tools",
        oracleBidsEnabled: true,
    },
    {
        ...baseSepolia,
        reservoirBaseUrl: reservoirChains.baseSepolia.baseApiUrl,
        proxyApi: "/reservoir/base-sepolia",
        routePrefix: "base-sepolia",
        coingeckoId: "ethereum",
        wssUrl: "wss://ws-base-sepolia.reservoir.tools",
        oracleBidsEnabled: true,
    },
    {
        ...polygon,
        reservoirBaseUrl: reservoirChains.polygon.baseApiUrl,
        proxyApi: "/reservoir/polygon",
        routePrefix: "polygon",
        coingeckoId: "matic-network",
        wssUrl: "wss://ws-polygon.reservoir.tools",
        listingCurrencies: [
            {
                ...nativeCurrencyEtherBase,
                symbol: "MATIC",
                coinGeckoId: "matic-network",
            },
            // {
            //   ...usdcCurrencyBase,
            //   contract: usdcContracts[polygon.id],
            // },
            {
                contract: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
                symbol: "WETH",
                decimals: 18,
                coinGeckoId: "weth",
            },
        ],
        oracleBidsEnabled: true,
    },
    {
        ...arbitrum,
        name: "Arbitrum",
        reservoirBaseUrl: reservoirChains.arbitrum.baseApiUrl,
        proxyApi: "/reservoir/arbitrum",
        routePrefix: "arbitrum",
        coingeckoId: "arbitrum-iou",
        wssUrl: "wss://ws-arbitrum.reservoir.tools",
        listingCurrencies: [
            { ...nativeCurrencyEtherBase, coinGeckoId: "arbitrum-iou" },
            // {
            //   ...usdcCurrencyBase,
            //   contract: usdcContracts[arbitrum.id],
            // },
        ],
    },
    {
        ...optimism,
        name: "Optimism",
        reservoirBaseUrl: reservoirChains.optimism.baseApiUrl,
        proxyApi: "/reservoir/optimism",
        routePrefix: "optimism",
        coingeckoId: "optimism",
        wssUrl: "wss://ws-optimism.reservoir.tools",
        listingCurrencies: [
            { ...nativeCurrencyEtherBase, coinGeckoId: "optimism" },
            // {
            //   ...usdcCurrencyBase,
            //   contract: usdcContracts[optimism.id],
            // },
        ],
    },
    {
        ...zora,
        name: "Zora",
        reservoirBaseUrl: reservoirChains.zora.baseApiUrl,
        proxyApi: "/reservoir/zora",
        routePrefix: "zora",
        coingeckoId: "ethereum",
    },
    {
        ...zoraTestnet,
        name: "Zora Goerli",
        reservoirBaseUrl: reservoirChains.zoraTestnet.baseApiUrl,
        proxyApi: "/reservoir/zora-goerli",
        routePrefix: "zora-goerli",
        coingeckoId: "ethereum",
    },
    {
        ...base,
        name: "Base",
        reservoirBaseUrl: reservoirChains.base.baseApiUrl,
        proxyApi: "/reservoir/base",
        routePrefix: "base",
        coingeckoId: "ethereum",
    },
    {
        ...scroll,
        name: "Scroll",
        reservoirBaseUrl: reservoirChains.scroll.baseApiUrl,
        proxyApi: "/reservoir/scroll",
        routePrefix: "scroll",
        coingeckoId: "ethereum",
    },
    {
        ...forma,
        name: "Forma",
        reservoirBaseUrl: formaReservoirChain.baseApiUrl,
        proxyApi: "/reservoir/forma",
        routePrefix: "forma",
        coingeckoId: "celestia",
        listingCurrencies: [
            {
                ...nativeCurrencyEtherBase,
                symbol: "TIA",
                coinGeckoId: "celestia",
            },
            {
                contract: "0xD5eacE1274dBF70960714F513db207433615a263",
                symbol: "WTIA",
                decimals: 18,
                coinGeckoId: "celestia",
            },
        ],
        paymentTokens: formaReservoirChain.paymentTokens,
    },
    {
        ...zkSync,
        name: "ZKsync Era",
        reservoirBaseUrl: reservoirChains.zkSync.baseApiUrl,
        proxyApi: "/reservoir/zksync",
        routePrefix: "zksync",
        coingeckoId: "ethereum",
    },
    {
        ...shape,
        name: "Shape",
        reservoirBaseUrl: shapeReservoirChain.baseApiUrl,
        proxyApi: "/reservoir/shape",
        routePrefix: "shape",
        coingeckoId: "ethereum",
        listingCurrencies: [
            nativeCurrencyEtherBase,
            {
                contract: "0x4200000000000000000000000000000000000006",
                symbol: "WETH",
                decimals: 18,
                coinGeckoId: "weth",
            },
        ],
        paymentTokens: shapeReservoirChain.paymentTokens,
    },
    {
        ...cyber,
        name: "Cyber",
        reservoirBaseUrl: cyberReservoirChain.baseApiUrl,
        proxyApi: "/reservoir/cyber",
        routePrefix: "cyber",
        coingeckoId: "ethereum",
        listingCurrencies: [
            nativeCurrencyEtherBase,
            {
                contract: "0x4200000000000000000000000000000000000006",
                symbol: "WETH",
                decimals: 18,
                coinGeckoId: "weth",
            },
        ],
        paymentTokens: cyberReservoirChain.paymentTokens,
    },
    {
        ...zero,
        name: "Zero",
        reservoirBaseUrl: zeroReservoirChain.baseApiUrl,
        proxyApi: "/reservoir/zero",
        routePrefix: "zero",
        coingeckoId: "ethereum",
        listingCurrencies: [
            nativeCurrencyEtherBase,
            {
                contract: "0xAc98B49576B1C892ba6BFae08fE1BB0d80Cf599c",
                symbol: "WETH",
                decimals: 18,
                coinGeckoId: "weth",
            },
        ],
        paymentTokens: zeroReservoirChain.paymentTokens,
    },
    {
        ...apechain,
        name: "ApeChain",
        reservoirBaseUrl: apechainReservoirChain.baseApiUrl,
        proxyApi: "/reservoir/apechain",
        routePrefix: "apechain",
        coingeckoId: "apecoin",
        listingCurrencies: [
            {
                ...nativeCurrencyEtherBase,
                symbol: "APE",
                coinGeckoId: "apecoin",
            },
            {
                contract: "0x48b62137edfa95a428d35c09e44256a739f6b557",
                symbol: "WAPE",
                decimals: 18,
                coinGeckoId: "wrapped-apecoin",
            },
        ],
        paymentTokens: apechainReservoirChain.paymentTokens,
    },
];
