import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useEffect } from "react";
import { UTILITY_COLOR } from "@hl/base-components/lib/theme/colors";
import { WEIGHT_BOLD } from "@hl/base-components/lib/theme/typography";
import { Anchor, Box, Card, Center, Divider, Flex, Group, Loader, Radio, ScrollArea, Stack, Text, useMantineTheme, } from "@mantine/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { _CollectionType, SortDirection, TokenSortOptions, } from "../../../apollo/graphql.generated";
import { USER_ROUTES } from "../../../config";
import { useAuth } from "../../auth/AuthContext";
import { ResizedImage } from "../../image";
import { useCollectionTokensCompactQuery } from "../queries/marketplace.graphql.generated";
const SelectToken = ({ selectToken, tokenId, collectionData, attributeKey, attributeValue, }) => {
    var _a, _b;
    const theme = useMantineTheme();
    const { walletAddress } = useAuth();
    const { address, chainId, marketplaceId, editionId, collectionType } = collectionData;
    const isQueryDisabled = !walletAddress || !address || !chainId || !marketplaceId;
    const attributesVar = !!attributeKey && !!attributeValue
        ? [{ name: attributeKey, values: [attributeValue] }]
        : null;
    const { data: tokensResponse, loading, error, fetchMore, } = useCollectionTokensCompactQuery({
        variables: {
            // @ts-ignore
            forceUserBalanceAddress: null,
            tokenAddress: address !== null && address !== void 0 ? address : "",
            after: null,
            first: 100,
            tokenNameOrId: null,
            sortBy: TokenSortOptions.PRICE,
            sortDirection: SortDirection.ASC,
            minted: true,
            attributes: attributesVar,
            chainId: chainId !== null && chainId !== void 0 ? chainId : 1,
            collectionMarketplaceId: marketplaceId !== null && marketplaceId !== void 0 ? marketplaceId : "",
            editionId: (editionId !== null && editionId !== void 0 ? editionId : "0").toString(),
            withOffers: null,
            withListings: null,
            ownerAddress: walletAddress,
            isImportedCollection: collectionType === _CollectionType.Imported,
        },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        skip: isQueryDisabled,
    });
    const tokens = (_b = (_a = tokensResponse === null || tokensResponse === void 0 ? void 0 : tokensResponse.getCollectionTokensV2) === null || _a === void 0 ? void 0 : _a.edges) !== null && _b !== void 0 ? _b : [];
    const pageInfo = tokensResponse === null || tokensResponse === void 0 ? void 0 : tokensResponse.getCollectionTokensV2.pageInfo;
    const nextCursor = pageInfo === null || pageInfo === void 0 ? void 0 : pageInfo.endCursor;
    useEffect(() => {
        var _a;
        if (tokens.length && tokenId) {
            const selectedToken = tokens.find((token) => (token === null || token === void 0 ? void 0 : token.tokenId) === tokenId);
            if (selectedToken) {
                selectToken({
                    tokenId: (_a = selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.tokenId) !== null && _a !== void 0 ? _a : "",
                    imageSrc: selectedToken === null || selectedToken === void 0 ? void 0 : selectedToken.imageUrl,
                });
            }
        }
    }, [tokens, tokenId]);
    return (_jsxs(Stack, { children: [_jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: "Your tokens" }), _jsx(Card, { px: 16, py: 12, children: _jsx(ScrollArea.Autosize, { maxHeight: 375, offsetScrollbars: true, children: _jsx(InfiniteScroll, { scrollThreshold: -20, dataLength: tokens.length, next: () => nextCursor &&
                            fetchMore({
                                variables: {
                                    after: nextCursor,
                                },
                                updateQuery: (previousResult, { fetchMoreResult }) => {
                                    var _a, _b, _c, _d;
                                    fetchMoreResult.getCollectionTokensV2.edges = [
                                        ...((_b = (_a = previousResult === null || previousResult === void 0 ? void 0 : previousResult.getCollectionTokensV2) === null || _a === void 0 ? void 0 : _a.edges) !== null && _b !== void 0 ? _b : []),
                                        ...((_d = (_c = fetchMoreResult === null || fetchMoreResult === void 0 ? void 0 : fetchMoreResult.getCollectionTokensV2) === null || _c === void 0 ? void 0 : _c.edges) !== null && _d !== void 0 ? _d : []),
                                    ];
                                    return fetchMoreResult;
                                },
                            }), hasMore: !!nextCursor, loader: _jsx(Loading, {}), children: tokens.length ? (tokens === null || tokens === void 0 ? void 0 : tokens.map((token, index) => {
                            return (_jsxs(Box, { children: [_jsxs(Group, { noWrap: true, children: [_jsx(Box, { p: 8, bg: UTILITY_COLOR.TABLE_ROW_BACKGROUND, children: _jsx(ResizedImage, { src: token === null || token === void 0 ? void 0 : token.imageUrl, width: 40 }) }), _jsx(Flex, { justify: "space-between", align: "center", sx: { flexGrow: 1 }, children: _jsx(Radio, { labelPosition: "left", w: "100%", label: _jsx(Text, { size: "sm", fw: WEIGHT_BOLD, children: `#${token === null || token === void 0 ? void 0 : token.tokenId}` }), styles: {
                                                        body: { width: "100%" },
                                                        labelWrapper: { width: "100%" },
                                                    }, checked: (token === null || token === void 0 ? void 0 : token.tokenId) === tokenId, onChange: (_) => {
                                                        var _a;
                                                        return selectToken({
                                                            tokenId: (_a = token === null || token === void 0 ? void 0 : token.tokenId) !== null && _a !== void 0 ? _a : "",
                                                            imageSrc: token === null || token === void 0 ? void 0 : token.imageUrl,
                                                        });
                                                    } }) })] }), index !== tokens.length - 1 && _jsx(Divider, { my: "sm" })] }, token === null || token === void 0 ? void 0 : token.tokenId));
                        })) : (_jsxs(Stack, { spacing: "xs", children: [_jsx(Text, { size: "sm", align: "center", fw: WEIGHT_BOLD, mt: 8, children: loading ? (_jsx(Loading, {})) : error ? ("No tokens could be fetched, try again later") : ("You don’t have any eligible tokens") }), !loading && !error && !!walletAddress && (_jsxs(Text, { size: "sm", align: "center", children: ["Visit", " ", _jsx(Anchor, { size: "sm", sx: { color: theme.colors.secondaryText[0] }, fw: WEIGHT_BOLD, href: USER_ROUTES.user.marketplace.receivedOffers.replace(":slug", walletAddress !== null && walletAddress !== void 0 ? walletAddress : ""), children: "your profile" }), " ", "to see all your eligible offers"] }))] })) }) }) })] }));
};
const Loading = () => (_jsx(Center, { p: "lg", w: "100%", children: _jsx(Loader, {}) }));
export default SelectToken;
