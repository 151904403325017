import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { useEffect, useState } from "react";
import { useTransaction, useWaitForTransactionReceipt } from "wagmi";
import { logError } from "../../services/logger";
import { TransactionStateType, useTransactionsDispatch, } from "./TransactionContext";
import { useBackendTxQueries } from "./useBackendTxQueries";
const TransactionConfirmation = ({ id, hash, transactionType, metadata, chainId, crossChainId, relayerId, }) => {
    const dispatch = useTransactionsDispatch();
    const [enableWait, setEnableWait] = useState(false);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [createdEvmTx, setCreatedEvmTx] = useState(false);
    const { data: transactionData, isError, refetch, } = useTransaction({
        hash,
        chainId,
        query: {
            enabled: true,
        },
    });
    const { data } = useWaitForTransactionReceipt({
        hash,
        chainId,
        confirmations: 1,
        query: {
            enabled: enableWait,
        },
    });
    useEffect(() => {
        const redeemRelayerTaskId = metadata
            .redeemRelayerTaskId;
        if (redeemRelayerTaskId) {
            setEnableWait(true);
        }
    }, [metadata]);
    const handleCreateSuccess = (data) => {
        console.log("handleCreateSuccess", data);
        if (data) {
            setCreatedEvmTx(true);
            setTriggerUpdate(true);
            setEnableWait(true);
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_WEB2_TX_STATUS",
                payload: {
                    id,
                    createdEvmTx: true,
                },
            });
        }
    };
    const handleUpdateSuccess = (data) => {
        var _a, _b;
        const redeemRelayerTaskId = (_b = (_a = data.updateEvmCrosschainBurnTx) === null || _a === void 0 ? void 0 : _a.evmCrosschainBurnMetadata) === null || _b === void 0 ? void 0 : _b.redeemRelayerTaskId;
        if (redeemRelayerTaskId) {
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_RELAYER_TASK_ID",
                payload: {
                    id,
                    relayerTaskId: redeemRelayerTaskId,
                },
            });
        }
        if (data) {
            dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                type: "UPDATE_WEB2_TX_STATUS",
                payload: {
                    id,
                    updatedEvmTx: true,
                },
            });
        }
    };
    const handleError = (err, type, { log } = { log: true }) => {
        if (log)
            logError({
                err,
                message: `TransactionConfirmation: ${type}`,
            });
    };
    const { createEvmTx, updateEvmTx } = useBackendTxQueries(handleCreateSuccess, handleUpdateSuccess, (err, type) => {
        handleError(err, type);
    });
    //Create EVM Txn
    useEffect(() => {
        console.log({ isError }, "SHOULD CREATE EVM Txn");
        if (isError) {
            setEnableWait(true);
        }
    }, [isError]);
    //Create EVM Txn
    useEffect(() => {
        console.log({ transactionData }, "SHOULD CREATE EVM Txn");
        if (transactionData) {
            createEvmTx(transactionData, transactionType, metadata, chainId, crossChainId, relayerId);
        }
    }, [transactionData]);
    //Update EVM Txn
    useEffect(() => {
        async function run() {
            if (data) {
                if (!createdEvmTx) {
                    refetch();
                }
                else {
                    await updateEvmTx(hash, transactionType);
                    dispatch === null || dispatch === void 0 ? void 0 : dispatch({
                        type: "UPDATE_TX_STATE",
                        payload: {
                            id,
                            type: TransactionStateType.Done,
                            receipt: data,
                        },
                    });
                }
            }
        }
        run();
    }, [data, triggerUpdate]);
    return _jsx(_Fragment, {});
};
export default TransactionConfirmation;
