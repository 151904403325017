import { serializeTransaction } from "viem";
import { TransactionStatus, TransactionType, } from "../../apollo/graphql.generated";
import { logError } from "../../services/logger";
import { GetPendingEvmTransactionsDocument, GetRecentEvmTransactionsDocument, } from "../tx-history/queries.graphql.generated";
import { useCreateEvm721AuctionTransactionMutation, useCreateEvm721BidTransactionMutation, useCreateEvm721MintTransactionMutation, useCreateEvm721RankedAuctionBidTransactionMutation, useCreateEvm721RankedAuctionReclaimBidFundsTxMutation, useCreateEvm721RankedAuctionClaimEarningsTxMutation, useCreateEvmApproveTransferTransactionMutation, useCreateEvmApproveTransferWrappedTransactionMutation, useCreateEvmCurrencyAllowanceTransactionMutation, useCreateEvmDeployTransactionMutation, useCreateEvmDepositFundsTransactionMutation, useCreateEvmMintCreatorReservesTransactionMutation, useCreateEvmSaleTransactionMutation, useCreateEvmUpdateCollectionTransactionMutation, useCreateEvmSeedRefreshTransactionMutation, useUpdateEvm721AuctionTransactionMutation, useUpdateEvm721BidTransactionMutation, useUpdateEvm721MintTransactionMutation, useUpdateEvm721RankedAuctionBidTransactionMutation, useUpdateEvmApproveTransferTxMutation, useUpdateEvmApproveTransferWrappedTxMutation, useUpdateEvmCreateMintVectorTransactionMutation, useUpdateEvmCurrencyAllowanceTransactionMutation, useUpdateEvmDeployTransactionMutation, useUpdateEvmDepositFundsTxMutation, useUpdateEvmMintCreatorReservesTransactionMutation, useUpdateEvmSaleTxMutation, useUpdateEvmUpdateCollectionTransactionMutation, useUpdateEvm721RankedAuctionClaimEarningsTxMutation, useCreateEvmSponsorMintTransactionMutation, useUpdateEvmSponsorMintTransactionMutation, useCreateEvmCrosschainBurnTransactionMutation, useUpdateEvmCrosschainBurnTransactionMutation, useCreateEvmTransferTransactionMutation, useUpdateEvmTransferTransactionMutation, useCreateEvmWithdrawDutchAuctionFundsTransactionMutation, useUpdateEvmWithdrawDutchAuctionFundsTxMutation, useCreateEvmSwapCurrencyTransactionMutation, useUpdateEvmSwapCurrencyTransactionMutation, useUpdateEvmSeedRefreshTransactionMutation, } from "./queries.graphql.generated";
export const useBackendTxQueries = (handleCreateSuccess, handleUpdateSuccess, handleError) => {
    const [createEvm721MintTransaction, { data: createEvm721MintTransactionData, error: createEvm721MintTransactionError, },] = useCreateEvm721MintTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    const [updateEvm721MintTransaction, { data: updateEvm721MintTransactionData, error: updateEvm721MintTransactionError, },] = useUpdateEvm721MintTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvm721MintTx.evmTxGraphql.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvm721BidTransaction, { data: createEvm721BidTransactionData, error: createEvm721BidTransactionError, },] = useCreateEvm721BidTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    const [updateEvm721BidTransaction, { data: updateEvm721BidTransactionData, error: updateEvm721BidTransactionError, },] = useUpdateEvm721BidTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvm721BidTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvm721RankedAuctionBidTransaction, { data: createEvm721RankedAuctionBidTransactionData, error: createEvm721RankedAuctionBidTransactionError, },] = useCreateEvm721RankedAuctionBidTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    const [createRankedAuctionReclaimBidFundsTx, { data: createRankedAuctionReclaimBidFundsTxData, error: createRankedAuctionReclaimBidFundsTxError, },] = useCreateEvm721RankedAuctionReclaimBidFundsTxMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    //
    const [createRankedAuctionClaimEarningsTx, { data: createRankedAuctionClaimEarningsTxData, error: createRankedAuctionClaimEarningsTxError, },] = useCreateEvm721RankedAuctionClaimEarningsTxMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    const [updateEvm721RankedAuctionBidTransaction, { data: updateEvm721RankedAuctionBidTransactionData, error: updateEvm721RankedAuctionBidTransactionError, },] = useUpdateEvm721RankedAuctionBidTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvm721RankedAuctionBidTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvm721RankedAuctionClaimEarningsTx, { data: updateEvm721RankedAuctionClaimEarningsTxData, error: updateEvm721RankedAuctionClaimEarningsTxError, },] = useUpdateEvm721RankedAuctionClaimEarningsTxMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvm721RankedAuctionClaimAuctionEarningsTx.status ===
                TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvm721AuctionTransaction, { data: createEvm721AuctionTransactionData, error: createEvm721AuctionTransactionError, },] = useCreateEvm721AuctionTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    const [updateEvm721AuctionTransaction, { data: updateEvm721AuctionTransactionData, error: updateEvm721AuctionTransactionError, },] = useUpdateEvm721AuctionTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvm721AuctionTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmDeployTransaction, { data: createEvmDeployTransactionData, error: createEvmDeployTransactionError, },] = useCreateEvmDeployTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmDeployTransaction, { data: updateEvmDeployTransactionData, error: updateEvmDeployTransactionError, },] = useUpdateEvmDeployTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmDeployTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmUpdateCollectionTransaction, { data: createEvmUpdateCollectionTransactionData, error: createEvmUpdateCollectionTransactionError, },] = useCreateEvmUpdateCollectionTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmUpdateCollectionTransaction, { data: updateEvmUpdateCollectionTransactionData, error: updateEvmUpdateCollectionTransactionError, },] = useUpdateEvmUpdateCollectionTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmUpdateCollectionTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmMintCreatorReservesTransaction, { data: createEvmMintCreatorReservesTransactionData, error: createEvmMintCreatorReservesTransactionError, },] = useCreateEvmMintCreatorReservesTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmMintCreatorReservesTransaction, { data: updateEvmMintCreatorReservesTransactionData, error: updateEvmMintCreatorReservesTransactionError, },] = useUpdateEvmMintCreatorReservesTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmMintCreatorReservesTx.evmTxGraphql.status ===
                TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmCreateMintVectorTransaction, { data: updateEvmCreateMintVectorTransactionData, error: updateEvmCreateMintVectorTransactionError, },] = useUpdateEvmCreateMintVectorTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmCreateMintVectorTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmSaleTx, { data: updateEvmSaleTxData, error: updateEvmSaleTxError },] = useUpdateEvmSaleTxMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmSaleTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmApproveTransferTx, { data: updateEvmApproveTransferTxData, error: updateEvmApproveTransferTxError, },] = useUpdateEvmApproveTransferTxMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmApproveTransferTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmApproveTransferWrappedTx, { data: updateEvmApproveTransferWrappedTxData, error: updateEvmApproveTransferWrappedTxError, },] = useUpdateEvmApproveTransferWrappedTxMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmApproveTransferWrappedTx.status ===
                TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmDepositFundsTx, { data: updateEvmDepositFundsTxData, error: updateEvmDepositFundsTxError },] = useUpdateEvmDepositFundsTxMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmDepositFundsTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmSaleTransaction, { data: createEvmSaleTransactionData, error: createEvmSaleTransactionError, },] = useCreateEvmSaleTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmApproveTransferTransaction, { data: createEvmApproveTransferTransactionData, error: createEvmApproveTransferTransactionError, },] = useCreateEvmApproveTransferTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmApproveTransferWrappedTransaction, { data: createEvmApproveTransferWrappedTransactionData, error: createEvmApproveTransferWrappedTransactionError, },] = useCreateEvmApproveTransferWrappedTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmDepositFundsTransaction, { data: createEvmDepositFundsTransactionData, error: createEvmDepositFundsTransactionError, },] = useCreateEvmDepositFundsTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmCurrencyAllowanceTransaction, { data: createEvmCurrencyAllowanceTransactionData, error: createEvmCurrencyAllowanceTransactionError, },] = useCreateEvmCurrencyAllowanceTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmCurrencyAllowanceTransaction, { data: updateEvmCurrencyAllowanceTransactionData, error: updateEvmCurrencyAllowanceTransactionError, },] = useUpdateEvmCurrencyAllowanceTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmCurrencyAllowanceTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmSponsorMintTransaction, { data: createEvmSponsorMintTransactionData, error: createEvmSponsorMintTransactionError, },] = useCreateEvmSponsorMintTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmSponsorMintTransaction, { data: updateEvmSponsorMintTransactionData, error: updateEvmSponsorMintTransactionError, },] = useUpdateEvmSponsorMintTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmSponsorMintTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmCrosschainBurnTransaction, { data: createEvmCrosschainBurnTransactionData, error: createEvmCrosschainBurnTransactionError, },] = useCreateEvmCrosschainBurnTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmCrosschainBurnTransaction, { data: updateEvmCrosschainBurnTransactionData, error: updateEvmCrosschainBurnTransactionError, },] = useUpdateEvmCrosschainBurnTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmCrosschainBurnTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmTransferTransaction, { data: createEvmTransferTransactionData, error: createEvmTransferTransactionError, },] = useCreateEvmTransferTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmTransferTransaction, { data: updateEvmTransferTransactionData, error: updateEvmTransferTransactionError, },] = useUpdateEvmTransferTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmTransferTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmSwapCurrencyTransaction, { data: createEvmSwapCurrencyTransactionData, error: createEvmSwapCurrencyTransactionError, },] = useCreateEvmSwapCurrencyTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmSwapCurrencyTransaction, { data: updateEvmSwapCurrencyTransactionData, error: updateEvmSwapCurrencyTransactionError, },] = useUpdateEvmSwapCurrencyTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmSwapCurrencyTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmWithdrawDutchAuctionFundsTransaction, { data: createEvmWithdrawDutchAuctionFundsTransactionData, error: createEvmWithdrawDutchAuctionFundsTransactionError, },] = useCreateEvmWithdrawDutchAuctionFundsTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmWithdrawDutchAuctionFundsTx, { data: updateEvmWithdrawDutchAuctionFundsTxData, error: updateEvmWithdrawDutchAuctionFundsTxError, },] = useUpdateEvmWithdrawDutchAuctionFundsTxMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmWithdrawDutchAuctionFundsTx.status ===
                TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const [createEvmSeedRefreshTransaction, { data: createEvmSeedRefreshTransactionData, error: createEvmSeedRefreshTransactionError, },] = useCreateEvmSeedRefreshTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            handleCreateSuccess(data);
        },
        onError(err) {
            handleError(err, "CREATE_EVM_TX_ERROR");
        },
    });
    const [updateEvmSeedRefreshTransaction, { data: updateEvmSeedRefreshTransactionData, error: updateEvmSeedRefreshTransactionError, },] = useUpdateEvmSeedRefreshTransactionMutation({
        refetchQueries: [
            GetPendingEvmTransactionsDocument,
            GetRecentEvmTransactionsDocument,
        ],
        onCompleted(data) {
            if (data.updateEvmSeedRefreshTx.status === TransactionStatus.MINED) {
                handleUpdateSuccess(data);
            }
        },
        onError(err) {
            handleError(err, "UPDATE_EVM_TX_ERROR");
        },
    });
    const serializeTx = (tx) => {
        try {
            if (!tx.chainId)
                throw new Error("chainId is required");
            const serializedTx = {
                chainId: tx.chainId,
                nonce: tx.nonce,
                to: tx.to ? tx.to : undefined,
                from: tx.from,
                hash: tx.hash,
                value: tx.value,
            };
            if (tx.type !== "legacy") {
                serializedTx.maxFeePerGas = tx.maxFeePerGas;
                serializedTx.maxPriorityFeePerGas = tx.maxPriorityFeePerGas;
                serializedTx.type = tx.type;
            }
            else {
                serializedTx.gasPrice = tx.gasPrice;
            }
            return serializeTransaction(serializedTx);
        }
        catch (err) {
            logError({
                err,
                message: `error serializing transaction ${JSON.stringify(tx)}`,
            });
            throw err;
        }
    };
    const createEvmTx = (tx, type, metadata, chainId, crossChainId, relayerId) => {
        console.log({ tx, type, metadata }, "About to create transaction");
        if (type === TransactionType.EVM_DEPLOY_COLLECTION) {
            createEvmDeployTransaction({
                variables: {
                    data: {
                        ...metadata,
                        serializedTx: serializeTx(tx),
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_721_MINT) {
            createEvm721MintTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                        chainId,
                        crossChainId,
                        relayerId,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_721_BID) {
            createEvm721BidTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                        crossChainId,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_RANKED_AUCTION_BID) {
            createEvm721RankedAuctionBidTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                        crossChainId,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_721_RANKED_AUCTION_RECLAIM_BID_FUNDS) {
            createRankedAuctionReclaimBidFundsTx({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                        crossChainId,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_721_RANKED_AUCTION_EARNINGS) {
            createRankedAuctionClaimEarningsTx({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                        crossChainId,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_721_AUCTION) {
            createEvm721AuctionTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                        crossChainId,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_UPDATE_ROYALTY ||
            type === TransactionType.EVM_SET_BASE_URI ||
            type === TransactionType.EVM_UPDATE_METADATA ||
            type === TransactionType.EVM_UPDATE_MINT_VECTOR ||
            type === TransactionType.EVM_CREATE_MINT_VECTOR ||
            type === TransactionType.EVM_CREATE_MECHANIC_VECTOR ||
            type === TransactionType.EVM_UPDATE_DUTCH_AUCTION_VECTOR ||
            type === TransactionType.EVM_UPDATE_RANKED_AUCTION_VECTOR ||
            type === TransactionType.EVM_DUTCH_AUCTION_REBATE) {
            createEvmUpdateCollectionTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_MINT_CREATOR_RESERVES) {
            createEvmMintCreatorReservesTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_TOKEN_SALE) {
            createEvmSaleTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_APPROVE_TRANSFER) {
            createEvmApproveTransferTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_APPROVE_TRANSFER_WRAPPED) {
            createEvmApproveTransferWrappedTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_DEPOSIT_FUNDS) {
            createEvmDepositFundsTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                        amount: tx.value.toString(),
                    },
                },
            });
        }
        if (type === TransactionType.EVM_CURRENCY_ALLOWANCE) {
            createEvmCurrencyAllowanceTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_SPONSOR_MINT) {
            createEvmSponsorMintTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...metadata,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_CROSSCHAIN_BURN) {
            const md = metadata;
            createEvmCrosschainBurnTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        burnId: md.burnId,
                        collectionId: md.collectionId,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_TOKEN_TRANSFER) {
            const { collectionId, ...rest } = metadata;
            createEvmTransferTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        collectionId: collectionId || "",
                        ...rest,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_DUTCH_AUCTION_CLAIM_FUNDS) {
            const { collectionId, nftContractAddress } = metadata;
            createEvmWithdrawDutchAuctionFundsTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        collectionId,
                        nftContractAddress,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_SWAP_CURRENCY) {
            const swapCurrencyMetadata = metadata;
            createEvmSwapCurrencyTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...swapCurrencyMetadata,
                        from: tx.from,
                        hash: tx.hash,
                        crossChainId,
                    },
                },
            });
        }
        if (type === TransactionType.EVM_SEED_REFRESH) {
            const seedRefreshMetadata = metadata;
            createEvmSeedRefreshTransaction({
                variables: {
                    input: {
                        serializedTx: serializeTx(tx),
                        ...seedRefreshMetadata,
                        from: tx.from,
                        hash: tx.hash,
                    },
                },
            });
        }
    };
    const updateEvmTx = (txHash, type) => {
        console.log({ txHash, type }, "About to update transaction");
        if (type === TransactionType.EVM_DEPLOY_COLLECTION) {
            updateEvmDeployTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_721_MINT) {
            updateEvm721MintTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_721_BID) {
            updateEvm721BidTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_RANKED_AUCTION_BID) {
            updateEvm721RankedAuctionBidTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_721_RANKED_AUCTION_EARNINGS) {
            updateEvm721RankedAuctionClaimEarningsTx({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_721_AUCTION) {
            updateEvm721AuctionTransaction({
                variables: { txHash },
            });
        }
        if ([
            TransactionType.EVM_UPDATE_ROYALTY,
            TransactionType.EVM_UPDATE_METADATA,
            TransactionType.EVM_SET_BASE_URI,
            TransactionType.EVM_UPDATE_MINT_VECTOR,
            TransactionType.EVM_UPDATE_DUTCH_AUCTION_VECTOR,
            TransactionType.EVM_UPDATE_RANKED_AUCTION_VECTOR,
            TransactionType.EVM_DUTCH_AUCTION_REBATE,
            TransactionType.EVM_DUTCH_AUCTION_CLAIM_FUNDS,
        ].includes(type)) {
            updateEvmUpdateCollectionTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_CREATE_MINT_VECTOR ||
            type === TransactionType.EVM_CREATE_MECHANIC_VECTOR) {
            updateEvmCreateMintVectorTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_MINT_CREATOR_RESERVES) {
            updateEvmMintCreatorReservesTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_TOKEN_SALE) {
            updateEvmSaleTx({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_APPROVE_TRANSFER) {
            updateEvmApproveTransferTx({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_APPROVE_TRANSFER_WRAPPED) {
            updateEvmApproveTransferWrappedTx({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_DEPOSIT_FUNDS) {
            updateEvmDepositFundsTx({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_CURRENCY_ALLOWANCE) {
            updateEvmCurrencyAllowanceTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_SPONSOR_MINT) {
            updateEvmSponsorMintTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_CROSSCHAIN_BURN) {
            updateEvmCrosschainBurnTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_TOKEN_TRANSFER) {
            updateEvmTransferTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_DUTCH_AUCTION_CLAIM_FUNDS) {
            updateEvmWithdrawDutchAuctionFundsTx({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_SWAP_CURRENCY) {
            updateEvmSwapCurrencyTransaction({
                variables: { txHash },
            });
        }
        if (type === TransactionType.EVM_SEED_REFRESH) {
            updateEvmSeedRefreshTransaction({
                variables: { txHash },
            });
        }
    };
    return {
        createEvmTx,
        updateEvmTx,
        data: {
            create: {
                createEvmDeployTransactionData,
                createEvm721MintTransactionData,
                createEvm721BidTransactionData,
                createEvm721RankedAuctionBidTransactionData,
                createRankedAuctionReclaimBidFundsTxData,
                createRankedAuctionClaimEarningsTxData,
                createEvm721AuctionTransactionData,
                createEvmUpdateCollectionTransactionData,
                createEvmMintCreatorReservesTransactionData,
                createEvmSaleTransactionData,
                createEvmApproveTransferTransactionData,
                createEvmApproveTransferWrappedTransactionData,
                createEvmDepositFundsTransactionData,
                createEvmCurrencyAllowanceTransactionData,
                createEvmSponsorMintTransactionData,
                createEvmCrosschainBurnTransactionData,
                createEvmTransferTransactionData,
                createEvmWithdrawDutchAuctionFundsTransactionData,
                createEvmSwapCurrencyTransactionData,
                createEvmSeedRefreshTransactionData,
            },
            update: {
                updateEvmDeployTransactionData,
                updateEvm721MintTransactionData,
                updateEvm721BidTransactionData,
                updateEvm721RankedAuctionBidTransactionData,
                updateEvm721RankedAuctionClaimEarningsTxData,
                updateEvm721AuctionTransactionData,
                updateEvmUpdateCollectionTransactionData,
                updateEvmMintCreatorReservesTransactionData,
                updateEvmCreateMintVectorTransactionData,
                updateEvmSaleTxData,
                updateEvmApproveTransferTxData,
                updateEvmApproveTransferWrappedTxData,
                updateEvmDepositFundsTxData,
                updateEvmCurrencyAllowanceTransactionData,
                updateEvmSponsorMintTransactionData,
                updateEvmCrosschainBurnTransactionData,
                updateEvmTransferTransactionData,
                updateEvmWithdrawDutchAuctionFundsTxData,
                updateEvmSwapCurrencyTransactionData,
                updateEvmSeedRefreshTransactionData,
            },
        },
        error: {
            create: {
                createEvmDeployTransactionError,
                createEvm721MintTransactionError,
                createEvm721BidTransactionError,
                createEvm721RankedAuctionBidTransactionError,
                createRankedAuctionReclaimBidFundsTxError,
                createRankedAuctionClaimEarningsTxError,
                createEvm721AuctionTransactionError,
                createEvmUpdateCollectionTransactionError,
                createEvmMintCreatorReservesTransactionError,
                createEvmSaleTransactionError,
                createEvmApproveTransferTransactionError,
                createEvmApproveTransferWrappedTransactionError,
                createEvmDepositFundsTransactionError,
                createEvmCurrencyAllowanceTransactionError,
                createEvmSponsorMintTransactionError,
                createEvmCrosschainBurnTransactionError,
                createEvmTransferTransactionError,
                createEvmWithdrawDutchAuctionFundsTransactionError,
                createEvmSwapCurrencyTransactionError,
                createEvmSeedRefreshTransactionError,
            },
            update: {
                updateEvmDeployTransactionError,
                updateEvm721MintTransactionError,
                updateEvm721BidTransactionError,
                updateEvm721RankedAuctionBidTransactionError,
                updateEvm721RankedAuctionClaimEarningsTxError,
                updateEvm721AuctionTransactionError,
                updateEvmUpdateCollectionTransactionError,
                updateEvmMintCreatorReservesTransactionError,
                updateEvmCreateMintVectorTransactionError,
                updateEvmSaleTxError,
                updateEvmApproveTransferTxError,
                updateEvmApproveTransferWrappedTxError,
                updateEvmDepositFundsTxError,
                updateEvmCurrencyAllowanceTransactionError,
                updateEvmSponsorMintTransactionError,
                updateEvmCrosschainBurnTransactionError,
                updateEvmTransferTransactionError,
                updateEvmWithdrawDutchAuctionFundsTxError,
                updateEvmSwapCurrencyTransactionError,
                updateEvmSeedRefreshTransactionError,
            },
        },
        serializeTx,
    };
};
