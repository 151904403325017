import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
import { memo, useEffect, useState } from "react";
import { useWaitForTransactionReceipt } from "wagmi";
import { useUpdateEvmDeployTransactionMutation } from "../evm-tx/queries.graphql.generated";
import { GetPendingEvmTransactionsDocument, GetRecentEvmTransactionsDocument, } from "./queries.graphql.generated";
const REQUIRED_CONFIRMATIONS = 1;
export const TX_COMPLETE_EVENT = "TxUpdater:transactionComplete";
const TxUpdater = ({ tx, refetchQueriesOnMined }) => {
    const [isPending, setIsPending] = useState(tx.status !== "MINED");
    const [updateEvmDeployTransaction] = useUpdateEvmDeployTransactionMutation();
    const { data: receipt } = useWaitForTransactionReceipt({
        hash: tx.txHash,
        confirmations: REQUIRED_CONFIRMATIONS,
        query: {
            enabled: isPending,
        },
    });
    useEffect(() => {
        if (isPending && (receipt === null || receipt === void 0 ? void 0 : receipt.transactionHash)) {
            updateEvmDeployTransaction({
                variables: { txHash: receipt.transactionHash },
                refetchQueries: [
                    GetRecentEvmTransactionsDocument,
                    GetPendingEvmTransactionsDocument,
                    ...refetchQueriesOnMined,
                ],
            }).then(() => {
                var _a;
                setIsPending(false);
                document.dispatchEvent(new CustomEvent(TX_COMPLETE_EVENT, {
                    detail: (_a = tx.evmDeployCollectionMetadata) === null || _a === void 0 ? void 0 : _a.collectionId,
                }));
            });
        }
    }, [receipt === null || receipt === void 0 ? void 0 : receipt.transactionHash]);
    return _jsx(_Fragment, {});
};
export default memo(TxUpdater);
