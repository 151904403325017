import { ReactNode, useEffect, useState } from "react";

import {
  SupportedCrossChainMainnets,
  SupportedCrossChainTestnets,
  useSupportedChains,
} from "@hl/shared-features/lib/features/chain";
import { GetSupportedChainsQuery } from "@hl/shared-features/lib/features/chain/configuration.graphql.generated";
import { getChainLabel } from "@hl/shared-features/lib/utils/blockchain";
import {
  getCurrencySymbol,
  getNativeCurrencyIcon,
} from "@hl/shared-features/lib/utils/currency";
import { formatEther } from "ethers/lib/utils";
import { useAccount, useConfig } from "wagmi";
import { getBalance } from "wagmi/actions";

export type ChainBalance = {
  chainId: number;
  address: `0x${string}`;
  balance: bigint;
};

export type ChainOption = {
  id: number;
  icon: ReactNode;
  label: string;
  default: boolean;
  balance: string;
};

export const useCrossChainMint = (collectionChainId: number) => {
  const { address, chain } = useAccount();
  const config = useConfig();
  const [chainBalances, setChainBalances] = useState<ChainBalance[]>([]);
  const [supported, setSupported] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { supportedChains } = useSupportedChains();

  const collectionChain = supportedChains.find(
    (chain) => chain.chainId === collectionChainId
  );

  const isTestnet = !!collectionChain?.testnet;

  useEffect(() => {
    async function fetchChainBalances(
      address: `0x${string}`,
      chains: GetSupportedChainsQuery["getSupportedChains"],
      isTestnet: boolean
    ) {
      const balances: ChainBalance[] = [];
      for (const chain of chains) {
        if (
          isTestnet &&
          (!chain.testnet ||
            !SupportedCrossChainTestnets.includes(chain.network))
        )
          continue;
        if (
          !isTestnet &&
          (chain.testnet ||
            !SupportedCrossChainMainnets.includes(chain.network))
        )
          continue;
        const balance = await getBalance(config, {
          address,
          chainId: chain.chainId,
        });
        balances.push({
          chainId: chain.chainId,
          address,
          balance: balance.value,
        });
      }
      setChainBalances(balances);
    }

    if (address && supportedChains && supportedChains.length)
      fetchChainBalances(address, supportedChains, isTestnet);
  }, [address, supportedChains, isTestnet, config]);

  useEffect(() => {
    if (collectionChain) {
      const supported = isTestnet
        ? SupportedCrossChainTestnets.includes(collectionChain?.network)
        : SupportedCrossChainMainnets.includes(collectionChain.network);
      setSupported(supported);
      setLoading(false);
    }
  }, [collectionChain]);

  const getChainOption = (
    chain: GetSupportedChainsQuery["getSupportedChains"][0],
    isDefault?: boolean
  ): ChainOption => {
    const chainBalance = chainBalances.find((b) => b.chainId === chain.chainId);
    const balance = chainBalance
      ? `${formatEther(chainBalance.balance).slice(0, 6)}`
      : "-";
    return {
      id: chain.chainId,
      icon: getNativeCurrencyIcon(chain.network, 20),
      label: getChainLabel(chain.network),
      balance: `${balance} ${getCurrencySymbol(chain.chainId)}`,
      default: !!isDefault,
    };
  };

  const options: ChainOption[] = [];
  if (supportedChains && collectionChain) {
    options.push(getChainOption(collectionChain, true));

    options.push(
      ...supportedChains
        .filter((chain) => {
          return (
            (isTestnet
              ? SupportedCrossChainTestnets.includes(chain.network)
              : SupportedCrossChainMainnets.includes(chain.network)) &&
            chain.chainId !== collectionChainId
          );
        })
        .map((c) => getChainOption(c))
    );
  }

  return {
    options,
    currentChain: chain?.id,
    supported,
    loading,
  };
};
