import { zeroAddress } from "viem";
export const forma = {
    id: 984122,
    name: "Forma",
    network: "forma",
    nativeCurrency: {
        symbol: "TIA",
        name: "TIA",
        decimals: 18,
    },
    rpcUrls: {
        default: {
            http: ["https://rpc.forma.art"],
            webSocket: ["wss://rpc.forma.art"],
        },
        public: {
            http: ["https://rpc.forma.art"],
            webSocket: ["wss://rpc.forma.art"],
        },
    },
    blockExplorers: {
        default: {
            name: "Forma Explorer",
            url: "https://explorer.forma.art",
        },
    },
    contracts: {
        multicall3: {
            address: "0xC5402e0BAF74c1042D72749cB8cA78c58dD93D6f",
            blockCreated: 1289867,
        },
    },
};
export const scroll = {
    id: 534352,
    name: "Scroll",
    network: "scroll",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: {
        default: {
            http: ["https://rpc.scroll.io"],
            webSocket: ["wss://wss-rpc.scroll.io/ws"],
        },
        public: {
            http: ["https://rpc.scroll.io"],
            webSocket: ["wss://wss-rpc.scroll.io/ws"],
        },
    },
    blockExplorers: {
        default: {
            name: "Scrollscan",
            url: "https://scrollscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 14,
        },
    },
};
export const shape = {
    id: 360,
    name: "Shape",
    network: "shape",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: {
        default: {
            http: ["https://mainnet.shape.network"],
        },
        public: {
            http: ["https://mainnet.shape.network"],
        },
    },
    blockExplorers: {
        default: {
            name: "Shapescan",
            url: "https://shapescan.xyz",
        },
    },
    contracts: {
        multicall3: {
            address: "0xAA45a6e4e1E6e43c14B366Dd0228874fb1DC0eF9",
            blockCreated: 14,
        },
    },
};
export const cyber = {
    id: 7560,
    name: "Cyber",
    network: "cyber",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: {
        default: {
            http: ["https://cyber.alt.technology"],
            webSocket: ["wss://cyber-ws.alt.technology"],
        },
        public: {
            http: ["https://cyber.alt.technology"],
            webSocket: ["wss://cyber-ws.alt.technology"],
        },
    },
    blockExplorers: {
        default: {
            name: "Cyberscan",
            url: "https://cyberscan.co",
        },
    },
    contracts: {
        multicall3: {
            address: "0x954386A2b103A8AD2B933E44Ea148036f73DC4B9",
            blockCreated: 14,
        },
    },
};
export const zero = {
    id: 543210,
    name: "Zero",
    network: "zero",
    nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
    rpcUrls: {
        default: {
            http: ["https://zero-network.calderachain.xyz"],
        },
        public: {
            http: ["https://zero-network.calderachain.xyz"],
        },
    },
    blockExplorers: {
        default: {
            name: "Zero Explorer",
            url: "https://explorer.zero.network",
        },
    },
    contracts: {
        multicall3: {
            address: "0xc65E164465A5b4262e09735ce12A5bC440519c41",
            blockCreated: 14,
        },
    },
};
export const apechain = {
    id: 33139,
    name: "ApeChain",
    network: "apechain",
    nativeCurrency: { name: "ApeCoin", symbol: "APE", decimals: 18 },
    rpcUrls: {
        default: {
            http: [
                "https://apechain-mainnet.g.alchemy.com/v2/PxrGSLd8TsZho5wzvTe2IKE7aMgkD-dY",
            ],
        },
        public: {
            http: ["https://rpc.apechain.com"],
        },
    },
    blockExplorers: {
        default: {
            name: "Apescan",
            url: "https://apescan.io",
        },
    },
    contracts: {
        multicall3: {
            address: "0x9c602CE508E41ccAF2cF997D93A9FbE0166D8aE6",
            blockCreated: 14,
        },
    },
};
export const formaReservoirChain = {
    id: 984122,
    name: "Forma",
    baseApiUrl: "https://api-forma.reservoir.tools",
    paymentTokens: [
        {
            chainId: 984122,
            address: zeroAddress,
            symbol: "TIA",
            name: "TIA",
            decimals: 18,
        },
        {
            chainId: 984122,
            address: "0xd5eace1274dbf70960714f513db207433615a263",
            symbol: "WTIA",
            name: "WTIA",
            decimals: 18,
        },
    ],
    checkPollingInterval: 1000,
};
export const shapeReservoirChain = {
    id: 360,
    name: "Shape",
    baseApiUrl: "https://api-shape.reservoir.tools",
    paymentTokens: [
        {
            chainId: 360,
            address: zeroAddress,
            symbol: "ETH",
            name: "ETH",
            decimals: 18,
        },
        {
            chainId: 360,
            address: "0x4200000000000000000000000000000000000006",
            symbol: "WETH",
            name: "WETH",
            decimals: 18,
        },
    ],
    checkPollingInterval: 1000,
};
export const cyberReservoirChain = {
    id: 7560,
    name: "Cyber",
    baseApiUrl: "https://api-cyber.reservoir.tools",
    paymentTokens: [
        {
            chainId: 7560,
            address: zeroAddress,
            symbol: "ETH",
            name: "ETH",
            decimals: 18,
        },
        {
            chainId: 7560,
            address: "0x4200000000000000000000000000000000000006",
            symbol: "WETH",
            name: "WETH",
            decimals: 18,
        },
    ],
    checkPollingInterval: 1000,
};
export const zeroReservoirChain = {
    id: 543210,
    name: "Zero",
    baseApiUrl: "https://api-zero.reservoir.tools",
    paymentTokens: [
        {
            chainId: 543210,
            address: zeroAddress,
            symbol: "ETH",
            name: "ETH",
            decimals: 18,
        },
        {
            chainId: 543210,
            address: "0xAc98B49576B1C892ba6BFae08fE1BB0d80Cf599c",
            symbol: "WETH",
            name: "WETH",
            decimals: 18,
        },
    ],
    checkPollingInterval: 1000,
};
export const apechainReservoirChain = {
    id: 33139,
    name: "ApeChain",
    baseApiUrl: "https://api-apechain.reservoir.tools",
    paymentTokens: [
        {
            chainId: 33139,
            address: zeroAddress,
            symbol: "APE",
            name: "APE",
            decimals: 18,
        },
        {
            chainId: 33139,
            address: "0x48b62137edfa95a428d35c09e44256a739f6b557",
            symbol: "WAPE",
            name: "WAPE",
            decimals: 18,
        },
    ],
    checkPollingInterval: 1000,
};
